import { Article, InformativeCard } from '@cg/module-frontend/src/components';
import { PlusCircleIcon } from '@cg/module-frontend/src/icons';
import React from 'react';
import { useNavigate } from 'react-router';
import { FeatureFlag } from '~/generated/models/FeatureFlag.ts';

type UserFlagProps = {
  flags: FeatureFlag[];
};

export default function UserFlags({ flags }: UserFlagProps) {
  const navigate = useNavigate();

  return (
    <Article>
      <InformativeCard
        title="Enabled Flags"
        secondary
        label={
          <button
            type="submit"
            className="flex items-center text-primary cursor-pointer"
            onClick={() => navigate('flags')}
          >
            <PlusCircleIcon className="size-6 text-2xl mr-2" />
            Enable/Disable Flags
          </button>
        }
      >
        <ul className="list-disc ml-10">
          {flags?.map((flag) => {
            return <li key={flag.id.getValue()}>{flag.feature}</li>;
          })}
        </ul>
      </InformativeCard>
    </Article>
  );
}
