import {
  ChartGraph,
  Checkbox,
  Dropdown,
  LineChart,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Series } from '@cg/module-frontend/src/components/chartboard/charts/LineChart.tsx';
import Analytic from '~/pages/analytics/components/Analytic.tsx';

const activeUserQuery = (days: number) => `
SELECT total, active, days, DATE_FORMAT(dateCreated, '%Y-%m-%d') AS dateCreated
FROM analytics_active_user
WHERE days = ${days}
  AND dateCreated >= DATE_SUB(CURDATE(), INTERVAL 30 DAY)
`;

export default function ActiveUsers() {
  const [label, setLabel] = React.useState('30 days');
  const [days, setDays] = React.useState(30);
  const [asRate, setAsRate] = React.useState(false);

  return (
    <Analytic
      filters={
        <div key="private-only">
          <Dropdown label={label} color="grey">
            <Dropdown.Item
              className="last:focus:rounded-t"
              onClick={() => {
                setLabel('7 days');
                setDays(7);
              }}
            >
              7 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLabel('14 days');
                setDays(14);
              }}
            >
              14 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLabel('21 days');
                setDays(21);
              }}
            >
              21 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLabel('30 days');
                setDays(30);
              }}
            >
              30 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLabel('45 days');
                setDays(45);
              }}
            >
              45 days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLabel('60 days');
                setDays(60);
              }}
            >
              60 days
            </Dropdown.Item>
          </Dropdown>
        </div>
      }
      description="Shows the list of active users defined as users who have logged in at least N times in the past D days."
      query={activeUserQuery(days).trim()}
    >
      {({ result }) => {
        if (!result || !Array.isArray(result)) {
          return null;
        }

        const x = result.map((q) => q.dateCreated);
        const y = result.map((q) => {
          if (asRate) {
            return (q.active / q.total) * 100;
          }
          return q.active;
        });
        const series: Series = { data: { x, y }, type: 'area' };

        return (
          <ChartGraph
            timelineDays={[7, 30, 90, 120, 180, 270, 365]}
            key="graph"
            hideDaySelector
            additionalFilters={
              <div
                className="flex flex-row items-center justify-center w-full"
                key="private-only"
              >
                Show as Rate
                <Checkbox
                  className="ml-2"
                  checked={asRate}
                  onClick={() => setAsRate(!asRate)}
                />
              </div>
            }
          >
            <LineChart series={[series]} color="#D8607A" precision={0} />
          </ChartGraph>
        );
      }}
    </Analytic>
  );
}

ActiveUsers.title = 'Active Users';
