/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The pricing type of the venue</p>
 */
export enum VenuePricingType {
	VenueFixed = 'VenueFixed',
	VenuePerHour = 'VenuePerHour',
	HeadFixed = 'HeadFixed',
	HeadVariable = 'HeadVariable'
}

