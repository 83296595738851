import { Article, Button, Img } from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { ExperienceId } from '@cg/common/src/ids';
import { useEffect } from 'react';
import { ExperienceWithTicketsAndHost } from '~/generated/models/ExperienceWithTicketsAndHost';
import { createCampaignHook } from '~/generated/clients/background/experiences/BackgroundExperiences.hooks';
import Campaign from '~/pages/campaigns/components/Campaign.tsx';

type Props = {
  experiences: ExperienceWithTicketsAndHost[];
};

export default function EmailCampaignPage({ experiences }: Props) {
  const [selectedIds, setSelectedIds] = React.useState<ExperienceId[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const {
    data: campaign,
    call: create,
    calling: creating,
  } = createCampaignHook(false);

  const toggleSelection = (id: ExperienceId) => {
    setSelectedIds((prev) =>
      prev.some((i) => i.isEqual(id))
        ? prev.filter((selectedId) => selectedId.isNotEqual(id))
        : [...prev, id],
    );
  };

  useEffect(() => {
    if (campaign) {
      setShowModal(true);
    }
  }, [campaign]);

  return (
    <>
      {campaign && showModal && (
        <Campaign campaign={campaign} close={() => setShowModal(false)} />
      )}
      <Article>
        <h2>Email Campaign</h2>
        <p>
          Select the upcoming experiences you want to use in your email
          campaign. Then click the Submit button to generate the email campaign.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-4">
          {experiences.map((experience) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              key={experience.id.getValue()}
              onClick={() => toggleSelection(experience.id)}
              className={`bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer ${
                selectedIds.some((i) => i.isEqual(experience.id))
                  ? ''
                  : 'opacity-50'
              }`}
            >
              <Img
                src={experience.bannerUrl}
                alt={experience.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold">{experience.title}</h3>
                <p className="text-black mt-2">{experience.summary}</p>
                <p className="text-black-lighter text-sm mt-4">
                  {DateUtils.formatted('MMM DD, YY', experience.startDate)}
                </p>
              </div>
            </div>
          ))}
        </div>

        <Button
          color="primary"
          loading={creating}
          disabled={selectedIds.length === 0}
          onClick={() =>
            create({
              body: {
                ids: selectedIds,
              },
            })
          }
        >
          Generate Campaign
        </Button>
      </Article>
    </>
  );
}
