import {
  Loader,
  Page,
  Section,
  ChartBoard,
  ChartCard,
  ChartCards,
  ChartGraph,
  LineChart,
  Checkbox,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { useEffect } from 'react';
import { Series } from '@cg/module-frontend/src/components/chartboard/charts/LineChart';
import { toCurrency } from '@cg/common/src/experiences/currency';
import { GraphData } from '~/generated/models/GraphData';
import {
  countSalesHook,
  countTicketsHook,
  graphSalesHook,
  graphTicketsSoldHook,
} from '~/generated/clients/background/aggregates/tickets/BackgroundAggregatesTickets.hooks';
import { calculateEMA, calculateTrend } from '~/utils/targets.ts';

export default function TicketsDashboardPage() {
  const [asRate, setAsRate] = React.useState(false);
  const [trend, setTrend] = React.useState<GraphData | null>(null);
  const [ema, setEma] = React.useState<GraphData | null>(null);
  const { data: ticketCount, calling: fetchingTicketCount } =
    countTicketsHook();
  const { data: salesCount } = countSalesHook();

  const [graph, setGraph] = React.useState<GraphData | null>(null);
  const {
    data: ticketGraphData,
    call: fetchTicketGraphData,
    calling: fetchingTicketGraphData,
  } = graphTicketsSoldHook(false);
  const {
    data: salesGraphData,
    call: fetchSalesGraphData,
    calling: fetchingSalesGraphData,
  } = graphSalesHook(false);

  const onCardChange = async (title: string, days: number) => {
    const filters = {
      filters: {
        ignoreCGHost: true,
        lastDays: days,
      },
    };

    if (title === 'Tickets') {
      filters.filters.ignoreCGHost = true;
      await fetchTicketGraphData(filters);
    }
    if (title === 'Non-CG Tickets') {
      filters.filters.ignoreCGHost = false;
      await fetchTicketGraphData(filters);
    }
    if (title === 'GMV') {
      filters.filters.ignoreCGHost = true;
      await fetchSalesGraphData(filters);
    }

    if (title === 'Non-CG GMV') {
      filters.filters.ignoreCGHost = false;
      await fetchSalesGraphData(filters);
    }
  };

  useEffect(() => {
    if (ticketGraphData) {
      const xData = ticketGraphData.x.map((x) =>
        DateUtils.dater(x).format('YYYY-MM-DD'),
      );

      if (asRate) {
        const yData = ticketGraphData.y;
        const x = xData.slice(1);
        const y = yData.slice(1).map((value, index) => value - yData[index]);
        setGraph({ x, y });

        const emaY = calculateEMA(y, 30);
        const trendY = calculateTrend(x, y);
        setEma({ x, y: emaY });
        setTrend({ x, y: trendY });
      } else {
        setGraph({ x: xData, y: ticketGraphData.y });
      }
    }
  }, [ticketGraphData, asRate]);

  useEffect(() => {
    if (salesGraphData) {
      const xData = salesGraphData.x.map((x) =>
        DateUtils.dater(x).format('YYYY-MM-DD'),
      );

      setGraph({ x: xData, y: salesGraphData.y });
    }
  }, [salesGraphData]);

  useEffect(() => {
    onCardChange('Tickets', 7);
  }, []);

  const getLineChart = () => {
    const series: Series[] = [];
    if (graph) {
      series.push({ data: graph, type: 'area' });
    }

    if (asRate) {
      if (ema) {
        series.push({
          data: ema,
          type: 'line',
          dashed: true,
          name: 'EMA (30 days)',
        });
      }
      if (trend) {
        series.push({
          data: trend,
          type: 'line',
          opacity: 0.5,
          color: '#000',
          dashed: true,
          name: 'Trend',
        });
      }
    }

    if (series.length > 0) {
      return <LineChart series={series} color="#D8607A" precision={0} />;
    }

    return null;
  };

  return (
    <Page>
      <Section>
        <h2>Tickets</h2>
        <Loader loading={fetchingTicketCount} horizontal>
          <ChartBoard onChange={onCardChange}>
            <ChartCards key="cards">
              <ChartCard
                key="total-tickets"
                title="Tickets"
                value={ticketCount?.all}
              />
              <ChartCard
                key="non-cg-tickets"
                title="Non-CG Tickets"
                value={ticketCount?.nonCG}
              />
              <ChartCard
                key="total-sales"
                title="GMV"
                value={toCurrency(salesCount?.all || 0, true)}
              />
              <ChartCard
                key="non-cg-sales"
                title="Non-CG GMV"
                value={toCurrency(salesCount?.nonCG || 0, true)}
              />
            </ChartCards>

            <ChartGraph
              key="graph"
              timelineDays={[7, 30, 90, 120, 180, 270, 365]}
              additionalFilters={
                <div
                  className="flex flex-row items-center justify-center w-full"
                  key="private-only"
                >
                  As Rate
                  <Checkbox
                    className="ml-2"
                    checked={asRate}
                    onClick={() => setAsRate(!asRate)}
                  />
                </div>
              }
            >
              <Loader
                loading={fetchingTicketGraphData || fetchingSalesGraphData}
                horizontal
              >
                {getLineChart()}
              </Loader>
            </ChartGraph>
          </ChartBoard>
        </Loader>
      </Section>
    </Page>
  );
}
