import { Modal } from '@cg/module-frontend/src/components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import VenueEditor from './VenueEditor';
import { createVenueHook } from '~/generated/clients/background/venues/BackgroundVenues.hooks.ts';
import { CreateVenueRequest } from '~/generated/models/CreateVenueRequest.ts';

type CreateHostProps = {
  show: boolean;
  onClose: () => void;
};

export default function CreateVenue({ show, onClose }: CreateHostProps) {
  const navigation = useNavigate();
  const {
    data: created,
    calling: creating,
    call: create,
  } = createVenueHook(false);

  useEffect(() => {
    if (created) {
      navigation(`/venues/${created.id.getValue()}`);
    }
  }, [created]);

  const onSubmit = async (body: CreateVenueRequest) => {
    await create({
      body,
    });
  };

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
    >
      <Modal.Header>Create a Venue</Modal.Header>
      <Modal.Body>
        <VenueEditor submitting={creating} onCreate={onSubmit} />
      </Modal.Body>
    </Modal>
  );
}
