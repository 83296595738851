import { boxConfig } from '@cg/module-frontend/src/config';
import { isValidEmail } from '@cg/common/src/utils';
import { UniversalSearcher } from './types';
import { listUsers } from '~/generated/clients/background/users/BackgroundUsers.client';

const searchByEmail: UniversalSearcher = {
  is: (text: string): boolean => {
    return isValidEmail(text);
  },
  apply: async (text: string) => {
    const response = await listUsers({
      filters: {
        email: text,
      },
    });

    if (!response.succeeded) {
      return {
        error: response.payload.message,
      };
    }

    const user = response.payload.result[0];
    if (!user) {
      return {
        error: 'User not found',
      };
    }

    return {
      url: `${boxConfig.baseUrls.backGround}/users/${user.id.getValue()}`,
    };
  },
};

export default searchByEmail;
