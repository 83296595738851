import {
  Autocomplete,
  Libraries,
  useJsApiLoader,
} from '@react-google-maps/api';
import React from 'react';
import { Loader } from '../loader';

const libraries = ['places'] as Libraries;

type AddressAutoCompleteProps = {
  children: React.ReactChild;
  autocompleteRef: React.MutableRefObject<google.maps.places.Autocomplete | null>;
  onChange: () => void;
};

export default function AddressAutoComplete({
  children,
  autocompleteRef,
  onChange,
}: AddressAutoCompleteProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return (
    <Loader loading={!isLoaded}>
      <Autocomplete
        className="w-full"
        onLoad={(r: google.maps.places.Autocomplete) => {
          autocompleteRef.current = r;
        }}
        onPlaceChanged={onChange}
      >
        {children}
      </Autocomplete>
    </Loader>
  );
}
