import {
  Article,
  Href,
  InformativeCard,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { boxConfig } from '@cg/module-frontend/src/config';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Experience } from '~/generated/models/Experience.ts';
import { Host } from '~/generated/models/Host.ts';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus.ts';
import { User } from '~/generated/models/User.ts';

type ExperienceDetailsProps = {
  experience: Experience;
  host: Host;
  owner?: User;
};
export default function ExperienceDetails({
  experience,
  host,
  owner,
}: ExperienceDetailsProps) {
  const url = `${boxConfig.baseUrls.commonGround}/e/${host.uniqueName}--${experience.uniqueName}`;

  return (
    <Article>
      <InformativeCard
        title={experience.title}
        label={
          <div className="flex space-x-2">
            <div className="flex space-x-4">
              {experience.status === ExperienceStatus.Draft ? '🟡' : '🟢'}
            </div>
            <div className="flex space-x-4">
              {experience.isPublic ? '🌐' : '🔒'}
            </div>
            {experience.isSecret && <div className="flex space-x-4">🤫</div>}
          </div>
        }
      >
        <div className="space-y-2">
          {owner && (
            <>
              <div className="font-bold">Created By</div>
              <Href to={`/users/${owner.id.getValue()}`}>
                {owner.firstName} {owner.lastName}
              </Href>
            </>
          )}
          <div className="font-bold">URL</div>
          <Href to={url} target="_blank">
            {url}
          </Href>
          <div className="font-bold">Unique Name</div>
          {experience.uniqueName}
          <div className="font-bold">Title</div>
          {experience.title}
          <div className="font-bold">Summary</div>
          {experience.summary}
          <div className="font-bold">Start Date</div>
          {DateUtils.formatted('MMM DD, h:mmA', experience.startDate, 'N/A')} (
          {DateUtils.fromNow(experience.startDate)})
          <div className="font-bold">End Date</div>
          {DateUtils.formatted('MMM DD, h:mmA', experience.endDate, 'N/A')} (
          {DateUtils.fromNow(experience.endDate)})
        </div>
      </InformativeCard>
    </Article>
  );
}
