import { Link } from 'react-router-dom';
import * as React from 'react';
import {
  SettingsIcon,
  FlagIcon,
  TicketIcon,
  VenueIcon,
  UsersOutlineIcon,
  ExperienceOutlineIcon,
  UserGroupOutlineIcon,
  AnalyticsIcon,
  EmailCampaignIcon,
} from '@cg/module-frontend/src/icons';
import { Nav, LeftNavigation, Img } from '@cg/module-frontend/src/components';
import classNames from 'classnames';

const navigation: Nav[] = [
  {
    to: '/users',
    icon: (active) => (
      <UsersOutlineIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Users',
    children: [
      {
        to: '/users/all',
        title: 'View All',
      },
      {
        to: '/users/abandoned',
        title: 'Abandoned',
      },
    ],
  },
  {
    to: '/tickets',
    icon: (active) => (
      <TicketIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Tickets',
  },
  {
    to: '/experiences',
    icon: (active) => (
      <ExperienceOutlineIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Experiences',
    children: [
      {
        to: '/experiences/all',
        title: 'View All',
      },
    ],
  },
  {
    to: '/hosts',
    icon: (active) => (
      <UserGroupOutlineIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Hosts',
  },
  {
    to: '/analytics',
    icon: (active) => (
      <AnalyticsIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Analytics',
  },
  {
    to: '/campaigns',
    icon: (active) => (
      <EmailCampaignIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Campaigns',
  },
  {
    to: '/venues',
    icon: (active) => (
      <VenueIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Venues',
  },
  {
    to: '/features',
    icon: (active) => (
      <FlagIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Feature Flags',
  },
  {
    to: '/dashboard/settings',
    icon: (active) => (
      <SettingsIcon
        className={classNames('size-6', {
          'text-primary': active,
          'text-black': !active,
        })}
      />
    ),
    title: 'Settings',
  },
];

function LeftNav() {
  return (
    <LeftNavigation
      logo={
        <Img
          src="https://assets.joincommonground.com/branding/logomark-pinkrose.svg"
          alt="logo"
          className="w-12"
        />
      }
      topNav={
        <div className="flex px-6">
          <div className="flex flex-col">
            <Link
              className="flex text-base font-medium leading-4 text-primary whitespace-nowrap items-center justify-center"
              to="/"
            >
              <Img
                src="https://assets.joincommonground.com/branding/logoword-pinkrose.svg"
                alt="logo"
                className="w-32"
              />
            </Link>
            {/* <div className="justify-center mt-1.5 text-sm tracking-tight"> */}
            {/*  Welcome to BackGround */}
            {/* </div> */}
          </div>
        </div>
      }
      navs={navigation}
    />
  );
}

export { LeftNav };
