import React from 'react';
import ToolTip from './ToolTip';

type Props = {
  className?: string;
  content: string;
};

export default function HelperTip({ content, className }: Props) {
  return <ToolTip content={content} icon="?" className={className} />;
}
