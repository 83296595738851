/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { countSales, countTickets, graphSales, GraphSalesArgs, graphTicketsSold, GraphTicketsSoldArgs } from './BackgroundAggregatesTickets.client';

export const countSalesHook = (immediateCall: boolean = true, ) => {
  return useHttp(countSales, { immediateCall })
};
export const countTicketsHook = (immediateCall: boolean = true, ) => {
  return useHttp(countTickets, { immediateCall })
};
export const graphSalesHook = (immediateCall: boolean = true, initArgs?: GraphSalesArgs) => {
  return useHttp(graphSales, { immediateCall, initArgs })
};
export const graphTicketsSoldHook = (immediateCall: boolean = true, initArgs?: GraphTicketsSoldArgs) => {
  return useHttp(graphTicketsSold, { immediateCall, initArgs })
};
