import * as React from 'react';
import { useNavigation } from 'react-router';
import { useEffect, useState } from 'react';
import DropdownUser from '../DropdownUser';
import UniversalSearchBar from '~/app/layout/dashboard/UniversalSearchBar';

function TopNav() {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (navigation.state === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [navigation.state]);

  return (
    <div className="sticky top-0 z-20 border-b border-grey-darker bg-grey">
      <header className="p-4 flex h-20 items-center justify-between">
        <div className="flex items-center gap-4">
          <UniversalSearchBar />
        </div>

        <div className="relative inline-block">
          <DropdownUser />
          {loading && (
            <div
              className="absolute inset-0 flex items-center justify-center"
              aria-hidden="true"
            >
              <div className="w-full h-full border-4 border-gray-200 border-t-primary rounded-full animate-spin" />
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export { TopNav };
