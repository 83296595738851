/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { UserId } from '@cg/common/src/ids/UserId';
import { UserRoleId } from '@cg/common/src/ids/UserRoleId';
import { FeatureFlagId } from '@cg/common/src/ids/FeatureFlagId';
import { User } from '../../../models/User';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceHostWithTickets } from '../../../models/ExperienceHostWithTickets';
import { ExperienceHost } from '../../../models/ExperienceHost';
import { UserRole } from '../../../models/UserRole';
import { FeatureFlag } from '../../../models/FeatureFlag';
import { UserWithTicketCount } from '../../../models/UserWithTicketCount';
import { UserStatus } from '../../../models/UserStatus';

export type AssignRoleArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
        roleId: UserRoleId,
    },
}

/**
 * @summary Assigns the UserRole to the User
 * @param {AssignRoleArgs} args
 */
export const assignRole = (args: AssignRoleArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/roles/{roleId}', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DisableUserFeatureArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
        featureFlagId: FeatureFlagId,
    },
}

/**
 * @summary Disables this feature for the user
 * @param {DisableUserFeatureArgs} args
 */
export const disableUserFeature = (args: DisableUserFeatureArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/features/{featureFlagId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type EnableUserFeatureArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
        featureFlagId: FeatureFlagId,
    },
}

/**
 * @summary Enables this feature for the user
 * @param {EnableUserFeatureArgs} args
 */
export const enableUserFeature = (args: EnableUserFeatureArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/features/{featureFlagId}', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchUserArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
}

/**
 * @summary Fetches the User by userId
 * @param {FetchUserArgs} args
 */
export const fetchUser = (args: FetchUserArgs): Promise<HttpResult<User>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchUserExperiencesArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
    filters?: {
        previousOnly?: boolean,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of Experiences the user is attending
 * @param {FetchUserExperiencesArgs} args
 */
export const fetchUserExperiences = (args: FetchUserExperiencesArgs): Promise<HttpResult<Page<ExperienceHostWithTickets>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchUserRolesArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of UserRole assigned to the User.
 * @param {FetchUserRolesArgs} args
 */
export const fetchUserRoles = (args: FetchUserRolesArgs): Promise<HttpResult<Page<UserRole>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/roles', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListUserFeaturesArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
}

/**
 * @summary Returns the list of features enabled for this user
 * @param {ListUserFeaturesArgs} args
 */
export const listUserFeatures = (args: ListUserFeaturesArgs): Promise<HttpResult<FeatureFlag[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/features', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListUsersArgs = {
    verifiedUser?: boolean,
    filters?: {
        email?: string,
        name?: string,
        status?: UserStatus,
        phoneNumber?: string,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Returns a list of Users
 * @param {ListUsersArgs} args
 */
export const listUsers = (args: ListUsersArgs): Promise<HttpResult<Page<UserWithTicketCount>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UnAssignRoleArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
        roleId: UserRoleId,
    },
}

/**
 * @summary Endpoint for removing UserRole from the User.
 * @param {UnAssignRoleArgs} args
 */
export const unAssignRole = (args: UnAssignRoleArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/users/{userId}/roles/{roleId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
