import { Loader } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useEffect } from 'react';
import ExperiencePage from './ExperiencePage';
import {
  listExperienceUsersHook,
  listTiersHook,
} from '~/generated/clients/background/experiences/BackgroundExperiences.hooks.ts';
import {
  fetchHostHook,
  getPaymentDetailHook,
} from '~/generated/clients/background/hosts/BackgroundHosts.hooks.ts';
import { getExperienceLoaderData } from '~/utils/loaderData.hooks.ts';
import { fetchUserHook } from '~/generated/clients/background/users/BackgroundUsers.hooks.ts';
import { listConfirmationsHook } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.hooks.ts';

export default function ExperienceComponent() {
  const experience = getExperienceLoaderData();
  const { data: paymentDetail, calling: fetchingPaymentDetail } =
    getPaymentDetailHook(true, { ids: { hostId: experience.hostId } });
  const { data: host, calling: fetchingHost } = fetchHostHook(true, {
    ids: { hostId: experience.hostId },
  });
  const { data: users, calling: fetchingUsers } = listExperienceUsersHook(
    true,
    { ids: { experienceId: experience.id } },
  );
  const { data: tiers, calling: fetchingTiers } = listTiersHook(true, {
    ids: { experienceId: experience.id },
  });
  const { data: confirmations, calling: fetchingConfirmations } =
    listConfirmationsHook(true, {
      ids: { experienceId: experience.id, hostId: experience.hostId },
    });
  const {
    data: owner,
    call: fetchOwner,
    calling: fetchingOwner,
  } = fetchUserHook(false);

  useEffect(() => {
    if (experience && !owner) {
      fetchOwner({
        ids: { userId: experience.ownerId },
      });
    }
  }, [experience, owner]);

  const confirmation = confirmations?.success;

  return (
    <Loader
      horizontal
      loading={
        fetchingHost ||
        fetchingUsers ||
        fetchingTiers ||
        fetchingPaymentDetail ||
        fetchingOwner ||
        fetchingConfirmations
      }
    >
      {host && paymentDetail && owner && (
        <ExperiencePage
          owner={owner}
          confirmation={confirmation}
          paymentDetail={paymentDetail}
          experience={experience}
          host={host}
          users={users?.result || []}
          tiers={tiers?.result || []}
        />
      )}
    </Loader>
  );
}
