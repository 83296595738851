import { useRouteLoaderData } from 'react-router-dom';
import { User } from '~/generated/models/User.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { Ticket } from '~/generated/models/Ticket.ts';
import { Venue } from '~/generated/models/Venue.ts';

const get = <T>(key: string) => {
  return useRouteLoaderData(key) as T;
};

export const getUserLoaderData = () => get<User>('user');

export const getExperienceLoaderData = () => get<Experience>('experience');

export const getTicketLoaderData = () =>
  get<{ user: User; ticket: Ticket }>('ticket');

export const getVenueLoaderData = () => get<Venue>('venue');
