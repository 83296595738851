import { Loader } from '@cg/module-frontend/src/components';
import * as React from 'react';
import {
  listHostsHook,
  listWaitlistedUsersHook,
} from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import HostsPage from './HostsPage';

export default function Hosts() {
  const {
    data: page,
    calling: fetchingHosts,
    nextPage: fetchNextPageHosts,
    callingMore,
  } = listHostsHook();
  const hasNextPage = page?.metadata?.nextPageToken !== null;
  const hosts = page?.result || [];

  const { data: waitlisted, calling: fetchingWaitlisted } =
    listWaitlistedUsersHook();

  return (
    <Loader
      horizontal
      loading={fetchingHosts && !callingMore && fetchingWaitlisted}
    >
      <HostsPage
        hosts={hosts}
        waitlisted={waitlisted?.result || []}
        nextPage={fetchNextPageHosts}
        hasNextPage={hasNextPage}
        callingMore={callingMore}
      />
    </Loader>
  );
}
