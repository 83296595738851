/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { CreateFeatureFlagRequest } from '../../../models/CreateFeatureFlagRequest';
import { FeatureFlag } from '../../../models/FeatureFlag';
import { FeatureFlagId } from '@cg/common/src/ids/FeatureFlagId';
import { Page } from '@cg/common/src/paging/Page';
import { UpdateFeatureFlagRequest } from '../../../models/UpdateFeatureFlagRequest';

export type CreateFeatureFlagArgs = {
    verifiedUser?: boolean,
    body: CreateFeatureFlagRequest,
}

/**
 * <p>Creates a new Feature Flag.</p>
 * @summary Endpoint for creating a new Feature Flag
 * @param {CreateFeatureFlagArgs} args
 */
export const createFeatureFlag = (args: CreateFeatureFlagArgs): Promise<HttpResult<FeatureFlag>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/features', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetFeatureFlagArgs = {
    verifiedUser?: boolean,
    ids: {
        featureFlagId: FeatureFlagId,
    },
}

/**
 * <p>Returns the feature flag.</p>
 * @summary Endpoint for returning a Feature Flag
 * @param {GetFeatureFlagArgs} args
 */
export const getFeatureFlag = (args: GetFeatureFlagArgs): Promise<HttpResult<FeatureFlag>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/features/{featureFlagId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListFeatureFlagsArgs = {
    verifiedUser?: boolean,
    filters?: {
        email?: string,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>Returns the Paginated list of all Feature Flags.</p>
 * @summary Endpoint for returning list of Feature Flags
 * @param {ListFeatureFlagsArgs} args
 */
export const listFeatureFlags = (args: ListFeatureFlagsArgs): Promise<HttpResult<Page<FeatureFlag>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/features', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateFeatureFlagArgs = {
    verifiedUser?: boolean,
    ids: {
        featureFlagId: FeatureFlagId,
    },
    body: UpdateFeatureFlagRequest,
}

/**
 * <p>Updates a Feature Flag.</p>
 * @summary Endpoint for updating a Feature Flag
 * @param {UpdateFeatureFlagArgs} args
 */
export const updateFeatureFlag = (args: UpdateFeatureFlagArgs): Promise<HttpResult<FeatureFlag>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/features/{featureFlagId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
