import { useNavigate } from 'react-router';
import {
  Article,
  Button,
  Page,
  Section,
  Table,
  TableRowItem,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Venue } from '~/generated/models/Venue';
import CreateVenue from './components/CreateVenue.tsx';

type VenuesPageProps = {
  venues: Venue[];
};
export default function VenuesPage({ venues }: VenuesPageProps) {
  const navigate = useNavigate();
  const [createVenue, showCreateVenue] = React.useState(false);

  const listHeaders = ['Name', 'Category', 'Description'];
  const listRows: TableRowItem[] = venues.map((venue) => {
    return {
      onClick: () => navigate(`/venues/${venue.id.getValue()}`),
      className: 'bg-white border-grey-darker cursor-pointer',
      key: venue.id.getValue(),
      cells: [venue.name, venue.category, venue.description],
    };
  });

  return (
    <Page>
      <CreateVenue show={createVenue} onClose={() => showCreateVenue(false)} />
      <Section>
        <Article>
          <div className="flex gap-5 flex-col w-full">
            <div className="flex justify-between items-center">
              <h2 className="justify-center text-black">All Venues</h2>
              <Button color="primary" onClick={() => showCreateVenue(true)}>
                Create Venue
              </Button>
            </div>

            <Table headers={listHeaders} rows={listRows} />
          </div>
        </Article>
      </Section>
    </Page>
  );
}
