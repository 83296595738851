import { useNavigate } from 'react-router-dom';
import React from 'react';

export const useOpenLink = () => {
  const navigate = useNavigate();

  return (url: string) => {
    return (event: React.MouseEvent) => {
      const newTab = event.metaKey || event.ctrlKey;
      if (newTab) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    };
  };
};
