import {
  Button,
  Label,
  Modal,
  Select,
  Textarea,
  TextInput,
  FileUpload,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail, isValidUrl } from '@cg/common/src/utils';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { PaymentDetailMethodMap } from '@cg/module-frontend/src/utils/general.ts';
import { CreateHostFromBGRequest } from '~/generated/models/CreateHostFromBGRequest';
import { listUsers } from '~/generated/clients/background/users/BackgroundUsers.client';
import { listHosts } from '~/generated/clients/background/hosts/BackgroundHosts.client';
import {
  createHostHook,
  updateHostSettingHook,
} from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import { User } from '~/generated/models/User';
import { PaymentDetailMethod } from '~/generated/models/PaymentDetailMethod';

enum HostType {
  'Personal' = 'Personal',
  'Professional' = 'Professional',
}

type CreateHostProps = {
  user: User | null;
  show: boolean;
  onClose: () => void;
};

export default function CreateHost({ user, show, onClose }: CreateHostProps) {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [hostType, setHostType] = useState<HostType | null>(null);
  const [summaryCount, setSummaryCount] = React.useState(0);
  const [descriptionCount, setDescriptionCount] = React.useState(0);
  const [hostImage, setHostImage] = React.useState<string | null>(null);

  const getForm = () => ({
    ownerEmail: user?.email || '',
    imageUrl: user?.imageUrl || '',
    isPersonal: !!user,
    uniqueName: user ? `${user.firstName?.toLowerCase()}-host` : '',
    friendlyName: user ? `${user.firstName}'s Personal` : '',
    summary: '',
    description: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<CreateHostFromBGRequest>({
    mode: 'onSubmit',
    defaultValues: useMemo(() => getForm(), [user]),
  });

  const setType = (type: HostType) => {
    setValue('isPersonal', type === HostType.Personal);
    setHostType(type);
  };

  useEffect(() => {
    if (user) {
      reset(getForm);
      setType(HostType.Personal);
    }
  }, [user]);

  const { data: host, calling: creating, call: create } = createHostHook(false);
  const {
    data: updatedHost,
    calling: updating,
    call: update,
  } = updateHostSettingHook(false);

  const onSubmit: SubmitHandler<CreateHostFromBGRequest> = async (payload) => {
    const body = { ...payload };
    if (!body.paymentDetail?.address) {
      delete body.paymentDetail;
    }
    if (!body.email) {
      delete body.email;
    }

    await create({ body });
  };

  useEffect(() => {
    if (host?.imageUrl) {
      navigate(`/hosts/${host.id.getValue()}`);
    }
    if (updatedHost) {
      navigate(`/hosts/${updatedHost.id.getValue()}`);
    }
  }, [host, updatedHost]);

  const isStepOne = hostType === null;
  const isStepTwo = hostType !== null && !host;
  const isStepThree = !!host && hostType === HostType.Professional;

  return (
    <Modal
      show={show}
      onClose={() => {
        setHostType(null);
        reset();
        onClose();
      }}
    >
      <Modal.Header>
        Create a new{' '}
        {hostType === HostType.Personal && <strong>Personal </strong>}
        {hostType === HostType.Professional && <strong>Professional </strong>}
        Host
      </Modal.Header>
      <Modal.Body>
        {isStepOne && (
          <div>
            <button
              className="flex flex-col w-full justify-center items-center text-center p-10 bg-secondary rounded-t font-semibold"
              type="button"
              onClick={() => setType(HostType.Personal)}
            >
              Make a Personal Host
            </button>
            <button
              className="flex flex-col w-full ustify-center items-center text-center bg-primary p-10 rounded-b font-semibold"
              type="button"
              onClick={() => setType(HostType.Professional)}
            >
              Make a Professional Host
            </button>
          </div>
        )}

        {isStepTwo && (
          <div className="space-y-4">
            <div className="flex flex-col">
              <Label htmlFor="ownerEmail" className="px-2 py-1" required>
                Owner Email
              </Label>
              <TextInput
                id="ownerEmail"
                placeholder="Owner's Email Address"
                {...register('ownerEmail', {
                  onChange: async (e) => {
                    const email = e.target.value;
                    setValue('ownerEmail', email, {
                      shouldDirty: true,
                    });

                    if (!isValidEmail(email)) {
                      setError('ownerEmail', {
                        message: 'Invalid email address',
                      });
                      return;
                    }

                    const response = await listUsers({
                      filters: { email },
                    });

                    if (!response.succeeded) {
                      setError('ownerEmail', {
                        message: response.payload.message,
                      });
                      return;
                    }

                    if (response.payload.result.length > 1) {
                      setError('ownerEmail', {
                        message: 'Multiple users found',
                      });
                      return;
                    }

                    const match = response.payload.result[0];
                    if (!match) {
                      setError('ownerEmail', { message: 'User not found' });
                      return;
                    }

                    setError('ownerEmail', {});
                    if (hostType === HostType.Personal) {
                      setValue('imageUrl', match.imageUrl, {
                        shouldDirty: true,
                      });
                    }
                  },
                })}
                helperText={
                  errors.ownerEmail?.message ? (
                    <span className="text-failure ml-3">
                      {errors.ownerEmail?.message}
                    </span>
                  ) : null
                }
              />
            </div>

            <div className="flex flex-col">
              {hostType === HostType.Personal && (
                <>
                  <Label htmlFor="image" className="px-2 py-1" required>
                    Image (readonly)
                  </Label>
                  <TextInput
                    id="image"
                    readOnly
                    placeholder="Host's Image URL"
                    {...register('imageUrl', {
                      // @ts-ignore
                      validate: async (imageUrl: string) => {
                        return isValidUrl(imageUrl) || 'Invalid URL';
                      },
                    })}
                    helperText={
                      errors.imageUrl?.message ? (
                        <span className="text-failure ml-3">
                          {errors.imageUrl?.message}
                        </span>
                      ) : null
                    }
                  />
                </>
              )}
            </div>

            <div className="flex flex-col">
              <Label htmlFor="uniqueName" className="px-2 py-1" required>
                Unique Name (32 max)
              </Label>
              <TextInput
                id="uniqueName"
                placeholder="Unique name of the host"
                {...register('uniqueName', {
                  onChange: async (e) => {
                    const uniqueName = e.target.value;
                    setValue('uniqueName', uniqueName, {
                      shouldDirty: true,
                    });

                    if (!uniqueName) {
                      setError('uniqueName', {
                        message: 'Please enter a unique name',
                      });
                      return;
                    }

                    const response = await listHosts({
                      filters: { uniqueName },
                    });

                    if (!response.succeeded) {
                      setError('uniqueName', {
                        message: response.payload.message,
                      });
                      return;
                    }

                    if (response.payload.result.length > 0) {
                      setError('uniqueName', {
                        message: 'Host with this name already exists',
                      });
                      return;
                    }

                    setError('uniqueName', {});
                  },
                })}
                helperText={
                  errors.uniqueName?.message ? (
                    <span className="text-failure ml-3">
                      {errors.uniqueName?.message}
                    </span>
                  ) : null
                }
              />
            </div>

            <div className="flex flex-col">
              <Label htmlFor="friendlyName" className="px-2 py-1" required>
                Friendly Name (64 max)
              </Label>
              <TextInput
                id="friendlyName"
                placeholder="Friendly name of the host"
                {...register('friendlyName', {
                  validate: (friendlyName: string) => {
                    if (!friendlyName) {
                      return 'Please enter a feature name';
                    }

                    if (friendlyName.length > 64) {
                      return 'Feature name must be less than 64 characters';
                    }

                    return true;
                  },
                })}
                helperText={
                  errors.friendlyName?.message ? (
                    <span className="text-failure ml-3">
                      {errors.friendlyName?.message}
                    </span>
                  ) : null
                }
              />
            </div>

            <div className="flex flex-col mt-4 relative">
              <Label htmlFor="summary" className="px-2 py-1" required>
                Summary (128 max)
              </Label>
              <Textarea
                id="summary"
                placeholder="Summary of the Host"
                rows={2}
                maxLength={128}
                {...register('summary', {
                  onChange: (e) => {
                    setSummaryCount(e.target.value.length);
                  },
                  validate: (summary: string) => {
                    if (!summary) {
                      return 'Please enter a summary';
                    }

                    if (summary.length > 128) {
                      return 'Summary must be less than 128 characters';
                    }

                    return true;
                  },
                })}
                helperText={
                  errors.summary?.message ? (
                    <span className="text-failure ml-3">
                      {errors.summary?.message}
                    </span>
                  ) : null
                }
              />
              <div className="absolute bottom-2 right-2 text-black-lighter">
                {summaryCount}
              </div>
            </div>

            <div className="flex flex-col mt-4 relative">
              <Label htmlFor="description" className="px-2 py-1" required>
                Description
              </Label>
              <Textarea
                id="description"
                rows={4}
                placeholder="Access to using Playground"
                {...register('description', {
                  onChange: (e) => {
                    setDescriptionCount(e.target.value.length);
                  },
                  validate: (description: string) => {
                    if (!description) {
                      return 'Please enter a description';
                    }

                    return true;
                  },
                })}
                helperText={
                  errors.description?.message ? (
                    <span className="text-failure ml-3">
                      {errors.description?.message}
                    </span>
                  ) : null
                }
              />
              <div className="absolute bottom-2 right-2 text-black-lighter">
                {descriptionCount}
              </div>
            </div>

            <div className="flex flex-col mt-4 relative">
              <Label htmlFor="email" className="px-2 py-1">
                Business Email
              </Label>
              <TextInput
                id="email"
                placeholder="The business email of the host"
                {...register('email', {
                  validate: (email?: string) => {
                    if (!email) {
                      return true;
                    }

                    if (!isValidEmail(email)) {
                      return 'Invalid email address';
                    }

                    return true;
                  },
                })}
                helperText={
                  errors.email?.message ? (
                    <span className="text-failure ml-3">
                      {errors.email?.message}
                    </span>
                  ) : null
                }
              />
            </div>

            <div className="flex flex-col space-y-2">
              <Label htmlFor="method" className="font-bold">
                Payment Method
              </Label>

              <Select id="method" {...register('paymentDetail.method')}>
                {Object.keys(PaymentDetailMethod).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {PaymentDetailMethodMap.get(key as PaymentDetailMethod)}
                    </option>
                  );
                })}
              </Select>
            </div>

            <div className="flex flex-col space-y-2">
              <Label htmlFor="address">Payment Address</Label>

              <TextInput
                type="text"
                id="address"
                placeholder="Enter your payment address - usually this is your email address"
                {...register('paymentDetail.address', {
                  validate: (address: string) => {
                    if (!address) {
                      return true;
                    }

                    if (address.length < 1) {
                      return 'Please enter a valid address';
                    }

                    return true;
                  },
                })}
              />
              {errors.paymentDetail?.address && (
                <p className="text-failure mt-2">
                  {errors.paymentDetail.address.message}
                </p>
              )}
            </div>
          </div>
        )}

        {isStepThree && (
          <div className="space-y-4">
            Update the Host Image
            <div className="flex flex-col justify-center items-center">
              <FileUpload
                background
                onUploading={() => setIsUploading(true)}
                onUploaded={(response) => {
                  setIsUploading(false);
                  setValue('imageUrl', response.imageUrl, {
                    shouldDirty: true,
                  });
                  setHostImage(response.imageUrl);
                }}
                ids={{
                  hostId: host?.id,
                }}
                onUploadFailed={() => {
                  /* noop */
                }}
                type="Host"
                maxSize={5}
                minWidth={360}
                minHeight={360}
              >
                <div
                  className={classNames(
                    'flex items-end justify-end w-fit h-fit',
                    {
                      'animate-[pulse_1.2s_infinite]': isUploading,
                    },
                  )}
                >
                  {/* @ts-ignore */}
                  <UserAvatar host={getValues()} imageSize="xlg" />
                </div>
              </FileUpload>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isStepTwo && (
          <Button
            color="primary"
            className="ml-auto"
            disabled={!isValid || creating}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        )}
        {isStepThree && (
          <Button
            color="primary"
            className="ml-auto"
            disabled={hostImage === null || updating}
            onClick={() => {
              if (host?.id && hostImage) {
                const body = { ...host };
                if (!body.email) {
                  delete body.email;
                }

                update({
                  ids: {
                    hostId: host.id,
                  },
                  body: {
                    ...body,
                    imageUrl: hostImage,
                  },
                });
              }
            }}
          >
            Upload Photo
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
