import { Card as FBCard, CardProps } from 'flowbite-react';
import classNames from 'classnames';

type Props = CardProps & {
  noShadow?: boolean;
};

export default function Card(props: Props) {
  const { noShadow, className, ...rest } = props;

  return (
    <FBCard
      {...rest}
      className={classNames(className, {
        'shadow-md rounded border border-grey': !noShadow,
      })}
    />
  );
}
