import { Section, Alert } from '@cg/module-frontend/src/components';
import * as React from 'react';

type PaymentNotificationProps = {
  unpaid: boolean;
  hasPaymentAddress: boolean;
  isFuture: boolean;
};
export default function PaymentNotification({
  unpaid,
  hasPaymentAddress,
  isFuture,
}: PaymentNotificationProps) {
  if (isFuture) {
    return null;
  }

  return (
    <Section className="space-y-2">
      {unpaid && (
        <Alert color="warning" rounded>
          This Experience has not been paid out yet. Please pay it out as soon
          as possible!
        </Alert>
      )}
      {!hasPaymentAddress && (
        <Alert color="warning" rounded>
          This Host has not set a Payment Method. Please contact Host to set one
          before processing payments.
        </Alert>
      )}
    </Section>
  );
}
