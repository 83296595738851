import { Accordion, Page, Section } from '@cg/module-frontend/src/components';
import * as React from 'react';
import ActiveUsers from './components/ActiveUsers';
import RepeatAttendees from './components/RepeatAttendees';

export default function Analytics() {
  return (
    <Page>
      <Section>
        <Accordion>
          <Accordion.Panel>
            <Accordion.Title>{ActiveUsers.title}</Accordion.Title>
            <Accordion.Content>
              <ActiveUsers />
            </Accordion.Content>
          </Accordion.Panel>

          <Accordion.Panel>
            <Accordion.Title>{RepeatAttendees.title}</Accordion.Title>
            <Accordion.Content>
              <RepeatAttendees />
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </Section>
    </Page>
  );
}
