import {
  HelperText,
  Label,
  TextInput,
  Button,
} from '@cg/module-frontend/src/components';
import {
  CloseOutlineIcon,
  DuplicateIcon,
  PlusIcon,
} from '@cg/module-frontend/src/icons';
import {
  UseFormRegister,
  UseFormSetValue,
  FieldErrors,
} from '@cg/module-frontend/src/hooks';
import { HolidayHoursOfOperation } from '~/generated/models/HolidayHoursOfOperation.ts';
import { HoursOfOperation } from '~/generated/models/HoursOfOperation.ts';

export type DayOfWeek = keyof HoursOfOperation;
const DAYS_OF_WEEK: DayOfWeek[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const HOP_REGEX =
  /\b(1[0-2]|0?[1-9])([ap]m)\s*(?:-|to)\s*(1[0-2]|0?[1-9])([ap]m)\b/i;
function formatHOP(range: string) {
  if (!range) {
    return '';
  }

  if (range.toLowerCase() === 'closed') {
    return 'Closed';
  }

  return range.replace(HOP_REGEX, (_, p1, p2, p3, p4) => {
    return `${p1}${p2.toUpperCase()} - ${p3}${p4.toUpperCase()}`;
  });
}

function capitalize(day: string) {
  return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
}

type VenueHOPProps = {
  hours?: HoursOfOperation;
  submitting: boolean;
  setValue: UseFormSetValue<HoursOfOperation>;
  register: UseFormRegister<HoursOfOperation>;
  errors?: FieldErrors<HoursOfOperation>;
};

export default function VenueHOP({
  submitting,
  setValue,
  register,
  hours,
  errors,
}: VenueHOPProps) {
  const holidays = hours?.holidays || [];
  return (
    <div className="flex flex-col">
      <Label htmlFor="hours" className="px-2" required>
        Hours of Operation
      </Label>
      <HelperText className="ml-2 mb-2">
        The Hours of Operation of this Venue. The values should be in the format
        of <span className="italic">8AM - 10PM</span> or{' '}
        <span className="italic">8AM to 10PM</span> or{' '}
        <span className="italic">Closed</span>.
      </HelperText>
      <div className="w-full flex flex-col relative gap-y-2">
        {DAYS_OF_WEEK.map((day, index: number) => {
          const title = capitalize(day);

          return (
            <div key={day}>
              <div className="grid grid-cols-4 items-center w-full gap-x-4">
                <span className="col-span-1 text-right">{title}</span>
                <TextInput
                  id={`hours.${day}`}
                  className="col-span-2"
                  type="text"
                  placeholder="Enter Time"
                  disabled={submitting}
                  {...register(day, {
                    onChange: (e) => {
                      setValue(day, formatHOP(e.target.value));
                    },
                    // @ts-ignore
                    validate: (value: string) => {
                      if (
                        !/\b(1[0-2]|0?[1-9])([AP]M) (?:-|to) (1[0-2]|0?[1-9])([AP]M)\b/.test(
                          value,
                        )
                      ) {
                        if (value.toLowerCase() === 'closed') {
                          return true;
                        }
                        return `Day ${title} is not in correct format.`;
                      }

                      return true;
                    },
                  })}
                />
                {index === 0 && hours?.monday && (
                  <Button
                    color="primary"
                    title="Copy to Others"
                    className="col-span-1 w-fit text-base"
                    onClick={() => {
                      if (hours.monday) {
                        setValue('tuesday', hours.monday);
                        setValue('wednesday', hours.monday);
                        setValue('thursday', hours.monday);
                        setValue('friday', hours.monday);
                        setValue('saturday', hours.monday);
                        setValue('sunday', hours.monday);
                      }
                    }}
                    icon={<DuplicateIcon className="size-6" />}
                  />
                )}
              </div>
              {errors?.[day]?.message && (
                <div className="flex items-center w-3/4">
                  <span className="w-1/4" />
                  <HelperText className="w-3/4 ml-6 mb-2 text-left">
                    <span className="text-failure">
                      {errors?.[day]?.message}
                    </span>
                  </HelperText>
                </div>
              )}
            </div>
          );
        })}
        {holidays.map((holiday: HolidayHoursOfOperation, index: number) => {
          const title = capitalize(holiday.day);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`holiday.${index}`}>
              <div className="grid grid-cols-4 items-center w-full gap-x-4">
                <TextInput
                  id={`holidays.${index}.day`}
                  className="col-span-1"
                  type="text"
                  placeholder="Holiday (eg. Christimas Day)"
                  disabled={submitting}
                  defaultValue={holidays[index].day}
                  {...register(`holidays.${index}.day`, {
                    onChange: (e) => {
                      setValue(
                        `holidays.${index}.day`,
                        formatHOP(e.target.value),
                      );
                    },
                  })}
                />
                <TextInput
                  id={`holidays.${index}.hours`}
                  className="col-span-2"
                  type="text"
                  placeholder="Enter Time"
                  disabled={submitting}
                  defaultValue={holidays[index].hours}
                  {...register(`holidays.${index}.hours`, {
                    onChange: (e) => {
                      setValue(
                        `holidays.${index}.hours`,
                        formatHOP(e.target.value),
                      );
                    },
                    validate: (value: string) => {
                      if (
                        !/\b(1[0-2]|0?[1-9])([AP]M) - (1[0-2]|0?[1-9])([AP]M)\b/.test(
                          value,
                        )
                      ) {
                        if (value.toLowerCase() === 'closed') {
                          return true;
                        }
                        return `Day ${title} is not in correct format.`;
                      }

                      return true;
                    },
                  })}
                />
                <div className="w-1/4 h-full items-center justify-end flex">
                  <CloseOutlineIcon
                    className="hover:cursor-pointer text-failure stroke-failure size-6"
                    onClick={() => {
                      const newHolidays = holidays.filter(
                        (_, i) => i !== index,
                      );
                      setValue('holidays', newHolidays);
                    }}
                  />
                </div>
              </div>
              {errors?.holidays?.[index]?.hours?.message && (
                <div className="flex items-center w-3/4">
                  <span className="w-1/4" />
                  <HelperText className="w-3/4 ml-6 mb-2 text-left">
                    <span className="text-failure">
                      {errors.holidays[index]?.hours?.message}
                    </span>
                  </HelperText>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Button
        color="primary"
        onClick={() => {
          setValue('holidays', [...holidays, { day: '', hours: '' }]);
        }}
        className="w-3/4 mt-2 flex items-center justify-center gap-x-2"
        icon={<PlusIcon className="size-6 " />}
      >
        <span className="flex items-center">New Holiday</span>
      </Button>
    </div>
  );
}
