import {
  Article,
  Table,
  TableHeaderItem,
  TableRowItem,
  UserAvatar,
  Card,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { TicketTier } from '~/generated/models/TicketTier';
import { UserWithTicket } from '~/generated/models/UserWithTicket.ts';

type GuestsDetailsProps = {
  users: UserWithTicket[];
  tiers: TicketTier[];
};
export default function GuestsDetails({ users, tiers }: GuestsDetailsProps) {
  const navigate = useNavigate();
  const headers: TableHeaderItem[] = [
    '',
    'Name',
    'Email',
    'Phone Number',
    'Diet',
    'Tier',
    'Payment Status',
  ];
  const rows: TableRowItem[] = users.map((user, index) => {
    const tier = tiers?.find((t) => user.ticket?.ticketTierId?.isEqual(t.id));
    const status = user.ticket?.status?.toString() || 'Unknown';

    return {
      onClick: () => navigate(`/users/${user.id}`),
      className: 'bg-white border-grey-darker cursor-pointer',
      key: user.id.getValue(),
      cells: [
        index + 1,
        <div className="flex flex-row items-center">
          <UserAvatar user={user} iconSize="md" expandable />
          <span className="ml-2 whitespace-nowrap">
            {user.firstName} {user.lastName}
          </span>
        </div>,
        user.email,
        user.phoneNumber,
        user.diet.join(','),
        {
          label: (
            <span className="bg-secondary rounded text-xs font-semibold p-1 px-2 text-primary cursor-pointer whitespace-nowrap">
              {tier?.name}
            </span>
          ),
        },
        {
          label: (
            <span className="bg-grey rounded text-xs font-semibold p-1 px-2 text-black whitespace-nowrap">
              {status}
            </span>
          ),
        },
      ],
    };
  });

  return (
    <Article>
      <Card>
        <h2>Guests</h2>
        <Table headers={headers} rows={rows} />
      </Card>
    </Article>
  );
}
