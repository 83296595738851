/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { countUsers, graphUserCount, GraphUserCountArgs } from './BackgroundAggregatesUsers.client';

export const countUsersHook = (immediateCall: boolean = true, ) => {
  return useHttp(countUsers, { immediateCall })
};
export const graphUserCountHook = (immediateCall: boolean = true, initArgs?: GraphUserCountArgs) => {
  return useHttp(graphUserCount, { immediateCall, initArgs })
};
