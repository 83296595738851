import { Loader } from '@cg/module-frontend/src/components';
import * as React from 'react';
import ExperiencesPage from './ExperiencesPage';
import { listExperiencesHook } from '~/generated/clients/background/experiences/BackgroundExperiences.hooks.ts';

export default function Experiences() {
  const {
    data: page,
    calling: fetchingHosts,
    nextPage: fetchNextPageHosts,
    callingMore,
  } = listExperiencesHook(true, {
    filters: {
      pageSize: 1000,
    },
  });
  const hasNextPage = page?.metadata?.nextPageToken !== null;
  const experiences = page?.result || [];

  return (
    <Loader horizontal loading={fetchingHosts && !callingMore}>
      <ExperiencesPage
        experiences={experiences}
        nextPage={fetchNextPageHosts}
        hasNextPage={hasNextPage}
        callingMore={callingMore}
      />
    </Loader>
  );
}
