import {
  Page,
  Article,
  Loader,
  Section,
  Checkbox,
  Table,
  TableRowItem,
} from '@cg/module-frontend/src/components';
import React from 'react';
import { getUserIdParam } from '@cg/module-frontend/src/hooks/params.hooks';
import { listFeatureFlagsHook } from '~/generated/clients/background/features/BackgroundFeatures.hooks';
import {
  disableUserFeatureHook,
  enableUserFeatureHook,
  listUserFeaturesHook,
} from '~/generated/clients/background/users/BackgroundUsers.hooks';
import { FeatureFlag } from '~/generated/models/FeatureFlag';

function UserFlagsPage() {
  const userId = getUserIdParam();
  const { data: userFlags, calling: fetchingUserFlags } = listUserFeaturesHook(
    true,
    {
      ids: {
        userId,
      },
    },
  );
  const { data: allFlags, calling: fetchingAllFlags } = listFeatureFlagsHook();
  const { calling: disablingFeature, call: disableFeature } =
    disableUserFeatureHook(false);
  const { calling: enablingFeature, call: enableFeature } =
    enableUserFeatureHook(false);

  const toggle = async (flag: FeatureFlag, currentState: boolean) => {
    if (currentState) {
      await disableFeature({
        ids: {
          userId,
          featureFlagId: flag.id,
        },
      });
    } else {
      await enableFeature({
        ids: {
          userId,
          featureFlagId: flag.id,
        },
      });
    }
  };
  const togglingFlag = enablingFeature || disablingFeature;
  const headers = ['Name', 'Description', 'Enabled'];
  const rows: TableRowItem[] = (allFlags?.result ?? []).map((flag) => {
    const match = userFlags?.find((f) => f.feature === flag.feature);
    const isEnabled = match !== undefined;

    return {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: flag.id.getValue(),
      cells: [
        flag.feature,
        flag.description,
        <Checkbox
          onClick={() => toggle(flag, isEnabled)}
          defaultChecked={isEnabled}
          disabled={togglingFlag}
        />,
      ],
    };
  });

  return (
    <Page>
      <Section>
        <Article>
          <h2>User Flags</h2>

          <p className="flex">
            <span className="text-warning">
              <strong>Warning: </strong>
              Toggling the check boxes will automatically enable/disable the
              feature!
            </span>
          </p>
          <Loader loading={fetchingAllFlags || fetchingUserFlags} horizontal />

          <div className="relative w-full">
            <Loader
              loading={togglingFlag}
              horizontal
              className="absolute top-0 left-0 w-10 h-20 flex justify-center items-center z-0"
            />
            <Table headers={headers} rows={rows} />
          </div>
        </Article>
      </Section>
    </Page>
  );
}

export default UserFlagsPage;
