/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { CreateEmailCampaignRequest } from '../../../models/CreateEmailCampaignRequest';
import { GeneratedEmailCampaignResponse } from '../../../models/GeneratedEmailCampaignResponse';
import { Experience } from '../../../models/Experience';
import { ExperienceId } from '@cg/common/src/ids/ExperienceId';
import { PreSignedUrl } from '../../../models/PreSignedUrl';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceWithTicketsAndHost } from '../../../models/ExperienceWithTicketsAndHost';
import { ExperienceWithTickets } from '../../../models/ExperienceWithTickets';
import { ExperienceStatus } from '../../../models/ExperienceStatus';
import { UserWithTicket } from '../../../models/UserWithTicket';
import { User } from '../../../models/User';
import { TicketTier } from '../../../models/TicketTier';

export type CreateCampaignArgs = {
    body: CreateEmailCampaignRequest,
}

/**
 * <p>Internal API for Campaigns CRUD</p>
 * @summary Creates a new Campaign
 * @param {CreateCampaignArgs} args
 */
export const createCampaign = (args: CreateCampaignArgs): Promise<HttpResult<GeneratedEmailCampaignResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/campaign', params);

    return http.post(url, {
        body: args.body,
    });
}
export type FetchExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for getting an Experience
 * @param {FetchExperienceArgs} args
 */
export const fetchExperience = (args: FetchExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences/{experienceId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GenerateReportArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for generating a report
 * @param {GenerateReportArgs} args
 */
export const generateReport = (args: GenerateReportArgs): Promise<HttpResult<PreSignedUrl>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences/{experienceId}/report', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListExperiencesArgs = {
    verifiedUser?: boolean,
    filters?: {
        name?: string,
        status?: ExperienceStatus,
        previousOnly?: boolean,
        upcomingOnly?: boolean,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of Experiences
 * @param {ListExperiencesArgs} args
 */
export const listExperiences = (args: ListExperiencesArgs): Promise<HttpResult<Page<ExperienceWithTicketsAndHost>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListExperienceUsersArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for getting users of an experience
 * @param {ListExperienceUsersArgs} args
 */
export const listExperienceUsers = (args: ListExperienceUsersArgs): Promise<HttpResult<Page<UserWithTicket>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences/{experienceId}/users', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListTiersArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for getting list of Ticket Tiers.
 * @param {ListTiersArgs} args
 */
export const listTiers = (args: ListTiersArgs): Promise<HttpResult<Page<TicketTier>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences/{experienceId}/ticket-tiers', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type PayExperienceArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for paying out a report
 * @param {PayExperienceArgs} args
 */
export const payExperience = (args: PayExperienceArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/experiences/{experienceId}/pay', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
