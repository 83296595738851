import {
  Article,
  Href,
  UserAvatar,
  Card,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Host } from '~/generated/models/Host';
import { PaymentDetail } from '~/generated/models/PaymentDetail.ts';

type HostDetailsProps = {
  host: Host;
  paymentDetail: PaymentDetail;
};
export default function HostDetails({ host, paymentDetail }: HostDetailsProps) {
  return (
    <Article>
      <Card>
        <div className="flex items-center justify-between w-full">
          <h2 className="text-left">{host.friendlyName}</h2>
          <div className="flex items-center">
            <Href to={`/hosts/${host.id}`}>
              <UserAvatar host={host} imageSize="lg" />
            </Href>
          </div>
        </div>
        <strong>Summary</strong>
        {host.summary}

        <strong>Description</strong>
        {host.description}

        <div className="space-x-2">
          <strong>Payment Method</strong>
          <span>{paymentDetail.method}</span>
        </div>

        <div className="space-x-2">
          <strong>Payment Address</strong>
          <span>{paymentDetail.address || 'Not Set'}</span>
        </div>
      </Card>
    </Article>
  );
}
