/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The category of the venue</p>
 */
export enum VenueCategory {
	Fitness = 'Fitness',
	Hall = 'Hall'
}

