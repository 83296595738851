import { Modal } from 'flowbite-react';
import React from 'react';
import { Button } from '../buttons';

type ConfirmModalProps = {
  title: string;
  body: React.ReactNode;
  onAccept: () => Promise<void>;
  onReject: () => Promise<void>;
  acceptText?: string;
  rejectText?: string;
  open: boolean;
  onClose: () => void;
};

export default function ConfirmModal({
  open,
  onClose,
  title,
  body,
  onAccept,
  onReject,
  acceptText,
  rejectText,
}: ConfirmModalProps) {
  const [isAccepting, setIsAccepting] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);
  acceptText = acceptText || 'Yes';
  rejectText = rejectText || 'No';

  return (
    <Modal
      show={open}
      onClose={onClose}
      position="center"
      size="md"
      theme={{
        content: {
          base: 'bg-black-800/50 w-fit object-contain',
        },
      }}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 min-w-72">{body}</div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <div className="flex justify-between items-center w-full">
          <div className="flex-grow" />
          <div className="flex gap-2">
            <Button
              color="secondary"
              onClick={async () => {
                try {
                  setIsRejecting(true);
                  await onReject();
                } finally {
                  setIsRejecting(false);
                }
              }}
              loading={isRejecting}
              disabled={isAccepting}
            >
              {rejectText}
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                try {
                  setIsAccepting(true);
                  await onAccept();
                } finally {
                  setIsAccepting(false);
                }
              }}
              disabled={isRejecting}
              loading={isAccepting}
            >
              {acceptText}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
