import { Img, Modal } from '@cg/module-frontend/src/components';
import React from 'react';
import { GeneratedEmailCampaignResponse } from '~/generated/models/GeneratedEmailCampaignResponse.ts';

type Props = {
  campaign: GeneratedEmailCampaignResponse;
  close: () => void;
};

export default function Campaign({ campaign, close }: Props) {
  return (
    <Modal show onClose={close} size="4xl">
      <Modal.Header>Email Campaign</Modal.Header>
      <Modal.Body>
        The Subject of the campaign is <strong>{campaign.subject}</strong>
        <br />
        The Body of the campaign is:
        <br />
        <br />
        <div className="flex items-center justify-center">
          <div className="bg-grey-lighter w-4/5 p-4 rounded space-y-2">
            <h3>{campaign.title}</h3>
            <p>Hey beautiful, ✨</p>
            <p>
              <strong>This week I learned ...</strong> {campaign.funFact.text} (
              {campaign.funFact.source})
            </p>
            <p>
              <strong>In other news:</strong> (to be filled)
            </p>
            <p>Questions? Just reply to this email.</p>
            <p>
              See you soon,
              <br />
              Zuki & Kousha x
            </p>
            <hr />
            <div className="grid grid-cols-1 gap-10">
              {campaign.experiences.map((experience) => {
                return (
                  <div className="grid grid-rows-[auto,auto,auto] rounded-lg">
                    <Img
                      src={experience.bannerUrl}
                      alt="Card image"
                      className="w-full h-auto rounded-t-lg"
                    />
                    <p>
                      🗓️ <strong>When:</strong> {experience.startDate}
                    </p>
                    <p>
                      🫖 <strong>The Tea:</strong> {experience.summary}
                    </p>
                    <p>
                      <strong>Link:</strong> {experience.link}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
