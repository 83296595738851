import { Href } from '@cg/module-frontend/src/components';
import React from 'react';

type ResultProps = {
  title: string;
  rows: {
    link: string;
    key: string;
    node: React.ReactNode;
  }[];
};

export default function Result({ title, rows }: ResultProps) {
  return (
    <>
      <h3>Multiple {title} matches found</h3>
      <ul className="list-disc pl-4">
        {rows.map((row) => {
          return (
            <li key={row.key}>
              <Href reload to={row.link}>
                {row.node}
              </Href>
            </li>
          );
        })}
      </ul>
    </>
  );
}
