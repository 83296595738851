import { Table } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons';

export type TableSortDirection = 'asc' | 'desc' | null;

type TableHeaderProps = {
  children: React.ReactNode;
  active?: boolean;
  filter?: (direction: TableSortDirection) => void;
};

export default function TableHeader({
  children,
  filter,
  active,
}: TableHeaderProps) {
  const [bool, setBool] = useState<null | boolean>(null);

  const onClick = () => {
    if (filter) {
      if (bool) {
        filter('asc');
        setBool(false);
      } else {
        filter('desc');
        setBool(true);
      }
    }
  };

  useEffect(() => {
    if (active === false) {
      setBool(null);
    }
  }, [active]);

  return (
    <Table.HeadCell>
      <div
        className={classNames('flex items-center gap-2 whitespace-nowrap', {
          'cursor-pointer': filter,
        })}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        {children}
        {filter && bool === null && (
          <div className="relative flex items-center justify-center w-6 h-6">
            <ChevronUpIcon className="absolute top-0 size-3" />
            <ChevronDownIcon className="absolute bottom-0 size-3" />
          </div>
        )}
        {filter && bool === true && <ChevronDownIcon className="size-3" />}
        {filter && bool === false && <ChevronUpIcon className="size-3" />}
      </div>
    </Table.HeadCell>
  );
}
