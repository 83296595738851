import { useAuth } from '@cg/module-frontend';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from '@cg/module-frontend/src/components';
import UserAvatar from '@cg/module-frontend/src/components/avatar/UserAvatar';
import { ProfileIcon } from '@cg/module-frontend/src/icons';
import React from 'react';
import { boxConfig } from '@cg/module-frontend/src/config';
import { sizeToPx } from '@cg/module-frontend/src/constant';

function DropdownUser() {
  const { login, isAuthenticated, self, logout } = useAuth();
  const profileUrl = `${boxConfig.baseUrls.commonGround}/profile/details`;

  return (
    <div className="flex items-center gap-10">
      <div className="lg:flex items-center gap-10">
        {!isAuthenticated && (
          <Button onClick={() => login()} color="primary">
            Sign Up / Login
          </Button>
        )}

        {isAuthenticated && (
          <Dropdown
            inline
            arrowIcon={false}
            label={
              <UserAvatar user={self?.user} iconSize="md" imageSize="md" />
            }
            placement="bottom-end"
          >
            <Dropdown.Header>
              <span className="block text-sm">
                {self?.user?.firstName} {self?.user?.lastName}
              </span>
              <span className="block truncate text-sm font-medium">
                {self?.user?.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item as={Link} to={profileUrl}>
              <ProfileIcon
                className="pr-2"
                aria-hidden="true"
                size={sizeToPx.sm}
              />
              My Profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown>
        )}
      </div>
    </div>
  );
}

export default DropdownUser;
