import { Loader, Page, Section } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { UserId } from '@cg/common/src/ids';
import { Host } from '~/generated/models/Host.ts';
import HostDetails from './components/HostDetails';
import {
  addHostUserHook,
  changeHostOwnerHook,
  listHostExperiencesHook,
  listHostUsersHook,
  removeHostUserHook,
  updateHostUserHook,
} from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import HostUsers from './components/HostUsers';
import HostExperiences from './components/HostExperiences';
import { User } from '~/generated/models/User';

type HostPageProps = {
  host: Host;
  owner: User;
  refetchHost: () => void;
};

export default function HostPage({ host, owner, refetchHost }: HostPageProps) {
  const {
    data: users,
    calling: fetchingUsers,
    call: fetchUsers,
  } = listHostUsersHook(true, {
    ids: {
      hostId: host.id,
    },
  });
  const { call: updateHostUser } = updateHostUserHook(false);

  const { data: experiences, calling: fetchingExperiences } =
    listHostExperiencesHook(true, {
      ids: {
        hostId: host.id,
      },
    });

  const { call: removeUser, calling: removingUser } = removeHostUserHook(false);
  const doRemoveUser = async (userId: UserId) => {
    const response = await removeUser({
      ids: {
        hostId: host.id,
        userId,
      },
    });
    if (response) {
      await fetchUsers({
        ids: {
          hostId: host.id,
        },
      });
    }
  };

  const { call: addUser, calling: addingUser } = addHostUserHook(false);
  const doAddUser = async (userId: UserId) => {
    const response = await addUser({
      ids: {
        hostId: host.id,
      },
      body: {
        userId,
      },
    });
    if (response) {
      await fetchUsers({
        ids: {
          hostId: host.id,
        },
      });
    }
  };

  const doUpdateUser = async (userId: UserId, isGhost: boolean) => {
    const response = await updateHostUser({
      ids: {
        hostId: host.id,
        userId,
      },
      body: {
        isGhost,
      },
    });
    if (response) {
      await fetchUsers({
        ids: {
          hostId: host.id,
        },
      });
    }
  };

  const { call: changeOwner, calling: changingOwner } =
    changeHostOwnerHook(false);
  const doChangeOwner = async (userId: UserId) => {
    if (owner.id.isEqual(userId)) {
      return;
    }

    const response = await changeOwner({
      ids: {
        hostId: host.id,
      },
      body: {
        userId,
      },
    });
    if (response) {
      await Promise.all([
        refetchHost(),
        fetchUsers({
          ids: {
            hostId: host.id,
          },
        }),
      ]);
    }
  };
  const experiencesWithHost = (experiences?.result || []).map((experience) => {
    return {
      ...experience,
      host,
    };
  });

  return (
    <Page>
      <Section>
        <HostDetails host={host} />
      </Section>

      <Section>
        <Loader loading={fetchingExperiences} horizontal>
          {experiences && <HostExperiences experiences={experiencesWithHost} />}
        </Loader>
      </Section>

      <Section>
        <Loader loading={fetchingUsers} horizontal>
          {users && (
            <HostUsers
              host={host}
              changeOwner={doChangeOwner}
              modifying={
                removingUser || addingUser || changingOwner || fetchingUsers
              }
              addUser={doAddUser}
              removeUser={doRemoveUser}
              updateUser={doUpdateUser}
              users={users?.result || []}
            />
          )}
        </Loader>
      </Section>
    </Page>
  );
}
