import { Loader, Page, Section } from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { listExperiencesHook } from '~/generated/clients/background/experiences/BackgroundExperiences.hooks.ts';
import EmailCampaignPage from '~/pages/campaigns/EmailCampgainPage.tsx';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus.ts';

export default function EmailCampaigns() {
  const { data, calling } = listExperiencesHook();
  const experiences = (data?.result ?? []).filter(
    (e) =>
      e.status === ExperienceStatus.Published &&
      DateUtils.isFuture(e.startDate),
  );

  return (
    <Page>
      <Section>
        <Loader loading={calling} horizontal />
        <EmailCampaignPage experiences={experiences} />
      </Section>
    </Page>
  );
}
