import {
  Page,
  Section,
  Article,
  Loader,
  Table,
  TableRowItem,
  Href,
} from '@cg/module-frontend/src/components';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import * as React from 'react';
import { listUsersHook } from '~/generated/clients/background/users/BackgroundUsers.hooks';
import { UserStatus } from '~/generated/models/UserStatus';

function AbandonedUsers() {
  const { data: signupUsers, calling: fetchingSignUpUsers } = listUsersHook(
    true,
    {
      filters: {
        status: UserStatus.Signup,
      },
    },
  );
  const headers = [
    'First Name',
    'Last Name',
    'Email',
    'Phone Number',
    'Last Logged In',
  ];
  const rows: TableRowItem[] = (signupUsers?.result ?? []).map((user) => {
    return {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: user.id.getValue(),
      cells: [
        user.firstName,
        user.lastName,
        <Href to={`mailto:${user.email}`}>{user.email}</Href>,
        <Href
          className="underline text-primary hover:text-primary-darker visited:text-primary"
          to={`tel:${user.phoneNumber}`}
        >
          {user.phoneNumber}
        </Href>,
        DateUtils.dater(user.lastLoggedIn).format('YYYY-MM-DD HH:mm:ss'),
      ],
    };
  });

  return (
    <Page>
      <Section>
        <Article>
          <div className="flex gap-5 flex-col w-full">
            <h2 className="justify-center text-black">
              Incomplete Signup Users
            </h2>

            <Loader horizontal loading={fetchingSignUpUsers}>
              <Table rows={rows} headers={headers} />
            </Loader>
          </div>
        </Article>
      </Section>
    </Page>
  );
}

export default AbandonedUsers;
