import { PostHog } from 'posthog-js/react';
import { Properties } from 'posthog-js';

const normalizePageName = (pathname: string) => {
  if (pathname.startsWith('/e/')) {
    return 'experienceInstance';
  }
  if (pathname.startsWith('/h/')) {
    return 'hostInstance';
  }
  if (pathname.startsWith('/profile/')) {
    return 'self';
  }
  if (pathname === '/') {
    return 'home';
  }

  return pathname;
};

export const handleOnClick = (
  posthog: PostHog,
  properties: Properties,
  isMobile: boolean,
) => {
  posthog.capture('$pageClick', {
    ...properties,
    $$pageName: normalizePageName(window.location.pathname),
    $$currentUrl: window.location.href,
    $$pathname: window.location.pathname,
    $$isMobile: isMobile,
  });
};
