import DiffViewer from 'react-codemirror-merge';

const { Original } = DiffViewer;
const { Modified } = DiffViewer;

type Props = {
  original: string;
  modified: string;
};

export default function CodeMirrorDiff({ original, modified }: Props) {
  return (
    <DiffViewer>
      <Original value={original} />
      <Modified value={modified} />
    </DiffViewer>
  );
}
