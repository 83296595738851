import {
  Section,
  Page,
  Article,
  Loader,
} from '@cg/module-frontend/src/components';
import { countExperiencesHook } from '~/generated/clients/background/aggregates/experiences/BackgroundAggregatesExperiences.hooks.ts';
import { countUsersHook } from '~/generated/clients/background/aggregates/users/BackgroundAggregatesUsers.hooks.ts';
import { countTicketsHook } from '~/generated/clients/background/aggregates/tickets/BackgroundAggregatesTickets.hooks.ts';
import DashboardPage from './DashboardPage.tsx';

export default function Dashboard() {
  const { data: experiencesCount, calling: fetchingExperiencesCount } =
    countExperiencesHook();
  const { data: usersCount, calling: fetchingUsersCount } = countUsersHook();
  const { data: ticketsCount, calling: fetchingTicketsCount } =
    countTicketsHook();

  return (
    <Page>
      <Section>
        <Article>
          <h2>Welcome to BackGround</h2>
          <p>
            Use the navigation on the left or the search bar at the top to get
            started.
          </p>
          <Loader
            loading={
              fetchingExperiencesCount ||
              fetchingUsersCount ||
              fetchingTicketsCount
            }
            horizontal
          >
            {experiencesCount && usersCount && ticketsCount && (
              <DashboardPage
                experiencesCount={experiencesCount}
                usersCount={usersCount}
                ticketsCount={ticketsCount}
              />
            )}
          </Loader>
        </Article>
      </Section>
    </Page>
  );
}
