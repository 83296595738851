import { ReactNode } from 'react';
import { UploadIcon } from '../../icons';

type EmptyBannerProps = {
  openFileSelector: () => void;
  primary: ReactNode;
  secondary?: ReactNode;
};

function EmptyBanner({
  openFileSelector,
  primary,
  secondary,
}: EmptyBannerProps) {
  return (
    <div
      className="w-full h-full bg-grey flex flex-col items-center justify-center text-black-lighter text-sm space-y-4"
      onClick={openFileSelector}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openFileSelector();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <UploadIcon className="size-8" />
      <p>{primary}</p>
      <p>{secondary}</p>
    </div>
  );
}

export default EmptyBanner;
