import {
  Article,
  Href,
  InformativeCard,
} from '@cg/module-frontend/src/components';
import React, { useState } from 'react';
import { toCurrency } from '@cg/common/src/experiences/currency.ts';
import { ConfirmModal } from '@cg/module-frontend/src/components/modals';
import { Ticket } from '~/generated/models/Ticket.ts';
import { User } from '~/generated/models/User.ts';
import { TicketTier } from '~/generated/models/TicketTier.ts';

type TicketDetailsProps = {
  ticket: Ticket;
  user: User;
  tier: TicketTier;
};

export default function TicketDetails({
  user,
  tier,
  ticket,
}: TicketDetailsProps) {
  const [showConfirm, setShowConfirm] = useState(false);
  const price = toCurrency(tier.price, true);

  return (
    <Article>
      <ConfirmModal
        title={`Confirm refunding ticket for ${user.firstName}?`}
        body={
          <p>
            Are you sure you want to refund the ticket for {user.firstName}{' '}
            {user.lastName}? They will receive {price} back.
          </p>
        }
        onAccept={async () => {
          setShowConfirm(false);
        }}
        onReject={async () => {
          setShowConfirm(false);
        }}
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
      />

      <InformativeCard title={tier.name} description={tier.description}>
        <p>
          <strong>Status:</strong> {ticket.status}
        </p>
        <p>
          <strong>Paying User:</strong>{' '}
          <Href to={`/users/${ticket.payingUserId.getValue()}`}>
            {ticket.payingUserId.getValue()}
          </Href>
        </p>
        <p>
          <strong>Price:</strong> {price}
        </p>
      </InformativeCard>
    </Article>
  );
}
