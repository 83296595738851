import {
  Article,
  Href,
  InformativeCard,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import React from 'react';
import { User } from '~/generated/models/User';

type UserDetailsProps = {
  user: User;
};

export default function UserDetails({ user }: UserDetailsProps) {
  return (
    <Article>
      <InformativeCard
        title={`${user.firstName} ${user.lastName}`}
        label={
          <UserAvatar user={user} iconSize="md" imageSize="md" expandable />
        }
      >
        <p>
          <strong>ID:</strong>{' '}
          <Href to={`/users/${user.id.getValue()}`}>{user?.id.getValue()}</Href>
        </p>
        <p>
          <strong>Status:</strong> {user?.status}
        </p>
        <p>
          <strong>First Name:</strong> {user?.firstName}
        </p>
        <p>
          <strong>Last Name:</strong> {user?.lastName}
        </p>
        <p>
          <strong>Email:</strong>{' '}
          <Href to={`mailto:${user.email}`}>{user.email}</Href>
        </p>
        <p>
          <strong>Phone Number:</strong>{' '}
          <Href
            className="underline text-primary hover:text-primary-darker visited:text-primary"
            to={`tel:${user.phoneNumber}`}
          >
            {user.phoneNumber}
          </Href>
        </p>
        <p>
          <strong>Gender:</strong> {user?.gender}
        </p>
        <p>
          <strong>Diet:</strong> {user?.diet?.join(', ')}
        </p>
        <p>
          <strong>Last Logged In:</strong>{' '}
          {DateUtils.dater(user?.lastLoggedIn).format('YYYY-MM-DD HH:mm:ss')}
        </p>
        <p>
          <strong>Login Count:</strong> {user.loginCount}
        </p>
      </InformativeCard>
    </Article>
  );
}
