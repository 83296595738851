import React, { useEffect, useState } from 'react';
import {
  Button,
  CodeEditor,
  Loader,
  sqlLang,
} from '@cg/module-frontend/src/components';
import { runQueryHook } from '~/generated/clients/background/analytics/BackgroundAnalytics.hooks.ts';
import { AnalyticsQueryResponse } from '~/generated/models/AnalyticsQueryResponse.ts';

type ChildrenProps = {
  result: AnalyticsQueryResponse | null;
  runningQuery: boolean;
};

type AnalyticsProps = {
  description: string;
  children: (props: ChildrenProps) => React.ReactElement | null;
  filters?: React.ReactNode;
  query: string;
};
export default function Analytic({
  query,
  children,
  description,
  filters,
}: AnalyticsProps) {
  const [code, setCode] = useState(query);

  useEffect(() => {
    setCode(query);
  }, [query]);

  const {
    data: result,
    call: runQuery,
    calling: runningQuery,
  } = runQueryHook(false);

  const run = () => {
    runQuery({
      body: {
        query: code,
      },
    });
  };

  return (
    <div className="flex flex-col">
      <p className="mb-2">{description}</p>
      {filters && <div className="flex justify-end mb-2">{filters}</div>}
      <p />
      <CodeEditor value={code} onChange={setCode} extensions={[sqlLang()]} />
      <div className="mt-auto pt-4 flex justify-end">
        <Button color="primary" onClick={run}>
          Run Query
        </Button>
      </div>
      <div className="flex flex-col mt-4">
        <Loader loading={runningQuery} horizontal>
          {children({ result, runningQuery })}
        </Loader>
      </div>
    </div>
  );
}
