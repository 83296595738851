import { Loader } from '@cg/module-frontend/src/components';
import React, { useEffect } from 'react';

import TicketPage from './TicketPage';
import { fetchExperienceHook } from '~/generated/clients/background/experiences/BackgroundExperiences.hooks.ts';
import { fetchHostHook } from '~/generated/clients/background/hosts/BackgroundHosts.hooks.ts';
import { fetchTicketTierHook } from '~/generated/clients/background/tickets/BackgroundTickets.hooks.ts';
import { getTicketLoaderData } from '~/utils/loaderData.hooks.ts';

export default function TicketComponent() {
  const { user, ticket } = getTicketLoaderData();
  const { data: experience, calling: fetchingExperience } = fetchExperienceHook(
    true,
    {
      ids: {
        experienceId: ticket.experienceId,
      },
    },
  );
  const {
    data: host,
    call: fetchHost,
    calling: fetchingHost,
  } = fetchHostHook(false);
  const { data: tier, calling: fetchingTier } = fetchTicketTierHook(true, {
    ids: {
      ticketTierId: ticket.ticketTierId,
    },
  });

  useEffect(() => {
    if (experience) {
      fetchHost({ ids: { hostId: experience.hostId } });
    }
  }, [experience]);

  return (
    <Loader
      horizontal
      loading={fetchingExperience || fetchingHost || fetchingTier}
    >
      {experience && host && tier && (
        <TicketPage
          tier={tier}
          ticket={ticket}
          user={user}
          experience={experience}
          host={host}
        />
      )}
    </Loader>
  );
}
