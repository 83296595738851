import {
  Article,
  Img,
  InformativeCard,
  Table,
  TableHeaderItem,
  TableRowItem,
} from '@cg/module-frontend/src/components';
import React from 'react';
import { useNavigate } from 'react-router';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import { ExperienceHostWithTickets } from '~/generated/models/ExperienceHostWithTickets.ts';

type UserExperiencesProps = {
  upcomingExperiences: ExperienceHostWithTickets[];
  previousExperiences: ExperienceHostWithTickets[];
};

export default function UserExperiences({
  upcomingExperiences,
  previousExperiences,
}: UserExperiencesProps) {
  const navigate = useNavigate();

  const headers: TableHeaderItem[] = ['Host', 'Title', 'Start Date', 'Tickets'];

  const getRows = (
    experiences: ExperienceHostWithTickets[],
  ): TableRowItem[] => {
    return experiences.map((experience) => {
      const count = experience.tickets?.length ?? 0;
      return {
        onClick: () => navigate(`/experiences/${experience.id.getValue()}`),
        className: 'bg-white border-grey-darker cursor-pointer',
        key: experience.id.getValue(),
        cells: [
          <div className="flex flex-row items-center">
            <Img
              src={experience.host.imageUrl ?? ''}
              className="w-10 h-10 rounded-full mr-2"
              alt={experience.host.friendlyName}
            />
            {experience.host.friendlyName}
          </div>,
          <div className="flex flex-row items-center">
            <Img
              src={experience.bannerUrl}
              className="w-10 h-10 rounded-full mr-2"
              alt={experience.uniqueName}
            />
            {experience.title}
          </div>,
          DateUtils.fromNow(experience.startDate),
          count === 0
            ? '❤️'
            : Array.from({ length: count })
                .map(() => '🎟️')
                .join(' '),
        ],
      };
    });
  };

  return (
    <Article className="flex flex-col">
      <InformativeCard className="space-y-4" title="Experiences" secondary>
        <h4>Upcoming Experiences</h4>
        <Table headers={headers} rows={getRows(upcomingExperiences)} />
        <h4>Previous Experiences</h4>
        <Table headers={headers} rows={getRows(previousExperiences)} />
      </InformativeCard>
    </Article>
  );
}
