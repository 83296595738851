import { Loader } from '@cg/module-frontend/src/components';
import { listVenuesHook } from '~/generated/clients/background/venues/BackgroundVenues.hooks.ts';
import VenuesPage from './VenuesPage';

export default function Venues() {
  const { data: venues, calling: fetching } = listVenuesHook(true, {
    filters: {
      pageSize: 1000,
    },
  });

  return (
    <Loader horizontal loading={fetching}>
      {venues && <VenuesPage venues={venues?.result ?? []} />}
    </Loader>
  );
}
