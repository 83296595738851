import * as React from 'react';
import { toast } from '../../utils/toast';
import { DuplicateIcon } from '../../icons';

interface CopyButtonProps {
  children: React.ReactNode;
  text: string;
  hideIcon?: boolean;
  message?: string;
}

export async function copyToClipboard(text: string, message?: string) {
  await navigator.clipboard.writeText(text);
  toast.success(message ?? 'Copied to clipboard');
}

export function CopyIconClipboard({
  children,
  text,
  hideIcon,
  message,
}: CopyButtonProps) {
  message = message ?? 'Copied to clipboard';

  return (
    <div
      className="cursor-pointer flex items-center space-x-2"
      role="button"
      tabIndex={0}
      onClick={async () => {
        await copyToClipboard(text, message);
      }}
      onKeyDown={async (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          await copyToClipboard(text, message);
        }
      }}
    >
      <div className="flex-1 flex justify-center items-center">
        <span className="leading-5">{children}</span>
      </div>
      {!hideIcon && <DuplicateIcon className="size-4" />}
    </div>
  );
}
