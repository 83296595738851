import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import {
  Img,
  Table,
  TableHeaderItem,
  TableRowItem,
  TableSortDirection,
  ToolTip,
} from '@cg/module-frontend/src/components';
import { useState } from 'react';
import { calculateRevenue } from '@cg/common/src/experiences/currency';
import { useOpenLink } from '@cg/module-frontend/src/hooks';
import { ExperienceWithTicketsAndHost } from '~/generated/models/ExperienceWithTicketsAndHost';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus';

type ExperiencesListTableProps = {
  experiences: ExperienceWithTicketsAndHost[];
};

const formattedDate = (date: Date | null) => {
  return DateUtils.formatted('MMM DD, h:mmA', date, 'N/A');
};

type Filters = {
  ticketsLeftSort: TableSortDirection | null;
  publishedSort: TableSortDirection | null;
  startDateSort: TableSortDirection | null;
};

const filterExperiences = (
  experiences: ExperienceWithTicketsAndHost[],
  filters: Filters,
): ExperienceWithTicketsAndHost[] => {
  let filtered = [...experiences];

  if (filters.publishedSort) {
    filtered = filtered.sort((a, b) => {
      const aTime =
        DateUtils.diff(a.datePublished, a.dateCreated, 'hours') || Infinity;
      const bTime =
        DateUtils.diff(b.datePublished, b.dateCreated, 'hours') || Infinity;
      return filters.publishedSort === 'asc' ? aTime - bTime : bTime - aTime;
    });
  }

  if (filters.ticketsLeftSort) {
    filtered = filtered.sort((a, b) => {
      let aTickets = Math.max(
        1,
        a.tickets.length /
          a.tiers.reduce((total, tier) => total + tier.count, 0),
      );
      if (Number.isNaN(aTickets)) {
        aTickets = 1;
      }
      let bTickets = Math.max(
        b.tickets.length /
          b.tiers.reduce((total, tier) => total + tier.count, 0),
      );
      if (Number.isNaN(bTickets)) {
        bTickets = 1;
      }
      return filters.ticketsLeftSort === 'asc'
        ? aTickets - bTickets
        : bTickets - aTickets;
    });
  }

  if (filters.startDateSort) {
    filtered = filtered.sort((a, b) => {
      const aTime = DateUtils.diffFromNow(a.startDate);
      const bTime = DateUtils.diffFromNow(b.startDate);

      return filters.startDateSort === 'asc' ? aTime - bTime : bTime - aTime;
    });
  }

  return filtered;
};

export default function ExperiencesListTable({
  experiences,
}: ExperiencesListTableProps) {
  const openLink = useOpenLink();
  const [ticketsLeftSort, setTicketsLeftSort] =
    useState<TableSortDirection | null>(null);
  const [startDateSort, setStartDateSort] = useState<TableSortDirection | null>(
    null,
  );
  const [publishedSort, setPublishedSort] = useState<TableSortDirection | null>(
    null,
  );

  const headers: TableHeaderItem[] = [
    'Host',
    'Title',
    'Status',
    'Views',
    {
      label: 'Published On',
      key: 'published',
      filter: setPublishedSort,
    },
    {
      label: 'Start Date',
      key: 'startDate',
      filter: setStartDateSort,
    },
    'Features',
    {
      label: 'Sales',
      key: 'tickets',
      filter: setTicketsLeftSort,
    },
    'Payment',
  ];

  const rows: TableRowItem[] = filterExperiences(experiences, {
    publishedSort,
    ticketsLeftSort,
    startDateSort,
  }).map((experience) => {
    const dollar = calculateRevenue(experience, false);
    const totalTickets = experience.tiers.reduce(
      (total, tier) => total + tier.count,
      0,
    );

    const timeToPublish = DateUtils.diff(
      experience.datePublished,
      experience.dateCreated,
      'hours',
    );

    const features: React.ReactNode[] = [];
    if (experience.tags?.some((t) => t.tag === 'aiGenerated')) {
      features.push(
        <ToolTip key="reselling" content="AI Generated">
          ✨
        </ToolTip>,
      );
    }

    return {
      key: experience.id.getValue(),
      className: 'bg-white border-grey-darker cursor-pointer',
      onClick: openLink(`/experiences/${experience.id.getValue()}`),
      cells: [
        <div className="flex flex-row items-center">
          <Img
            src={experience.host.imageUrl ?? ''}
            className="w-10 h-10 rounded-full mr-2"
            alt={experience.host.friendlyName}
          />
          {experience.host.friendlyName}
        </div>,
        <div className="flex flex-row items-center">
          <Img
            src={experience.bannerUrl}
            className="w-10 h-10 rounded-full mr-2"
            alt={experience.uniqueName}
          />
          {experience.title}
        </div>,
        <div className="flex justify-center items-center space-x-2">
          {experience.status === ExperienceStatus.Published ? '🟢 ' : '🟡 '}
          {experience.isPublic ? '🌐 ' : '🔒 '}
          {experience.isSecret ? '🤫 ' : ''}
          {experience.tickets.length === totalTickets ? '🎊 ' : ''}
        </div>,
        experience.views,
        {
          label: (
            <>
              {formattedDate(experience.datePublished)}
              <span className="flex font-semibold">
                {timeToPublish && `(took ${timeToPublish} hours)`}
              </span>
            </>
          ),
        },
        {
          label: (
            <>
              {formattedDate(experience.startDate)}
              <span className="flex font-semibold">
                {experience.startDate && (
                  <>({DateUtils.fromNow(experience.startDate)})</>
                )}
              </span>
            </>
          ),
        },
        features,
        <div>
          <div>
            🎟️ {experience.tickets.length} / {totalTickets}
          </div>
          <div>💰 {dollar}</div>
        </div>,
        <div>
          {experience.datePaid
            ? `💸 ${DateUtils.formatted('MMM DD, YY', experience.datePaid)}`
            : '⏳ Pending'}
        </div>,
      ],
    };
  });

  return <Table striped={false} headers={headers} rows={rows} />;
}
