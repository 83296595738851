import { boxConfig } from '@cg/module-frontend/src/config';
import React from 'react';
import { UniversalSearcher } from './types';
import { listUsers } from '~/generated/clients/background/users/BackgroundUsers.client';
import Result from './Result';

const searchByName: UniversalSearcher = {
  is: (text: string): boolean => {
    return text.startsWith('users:');
  },
  apply: async (text: string) => {
    const name = text.split('users:')[1];
    const response = await listUsers({
      filters: {
        name,
      },
    });

    if (!response.succeeded) {
      return {
        error: response.payload.message,
      };
    }

    const len = response.payload.result.length;
    if (len === 0) {
      return {
        error: 'User not found',
      };
    }

    if (len === 1) {
      const user = response.payload.result[0];
      return {
        url: `${boxConfig.baseUrls.backGround}/users/${user.id.getValue()}`,
      };
    }

    const rows = response.payload.result.map((user) => {
      return {
        link: `${boxConfig.baseUrls.backGround}/users/${user.id.getValue()}`,
        key: user.id.getValue(),
        node: (
          <>
            {user.firstName} {user.lastName} ({user.email})
          </>
        ),
      };
    });

    return {
      node: <Result title="user" rows={rows} />,
    };
  },
};

export default searchByName;
