import { Card } from 'flowbite-react';
import React from 'react';
import classNames from 'classnames';

type InformativeCardProps = {
  title: string;
  secondary?: boolean;
  label?: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
};

export default function InformativeCard({
  title,
  secondary,
  label,
  description,
  children,
  action,
  className,
}: InformativeCardProps) {
  return (
    <Card>
      <div className="flex items-center justify-between w-full">
        {!secondary && <h2 className="text-left">{title}</h2>}
        {secondary && <h3 className="text-left">{title}</h3>}

        {label && <div className="flex items-center">{label}</div>}
      </div>
      {description && <span>{description}</span>}
      <div className={classNames('w-full', className)}>{children}</div>
      {action && <div className="flex w-full">{action}</div>}
    </Card>
  );
}
