import {
  HelperText,
  Label,
  Select,
  Textarea,
  TextInput,
  Button,
} from '@cg/module-frontend/src/components';
import { CloseOutlineIcon, PlusIcon } from '@cg/module-frontend/src/icons';
import { FieldErrors, UseFormRegister } from '@cg/module-frontend/src/hooks';
import { VenuePricingType } from '~/generated/models/VenuePricingType.ts';
import { VenuePricing } from '~/generated/models/VenuePricing';

type VenuePricingProps = {
  prices: VenuePricing[];
  submitting: boolean;
  setValue: (values: VenuePricing[]) => void;
  register: UseFormRegister<VenuePricing[]>;
  errors: FieldErrors<VenuePricing[]>;
};

const VenuePricingTypeDescriptions: Record<VenuePricingType, string> = {
  [VenuePricingType.VenueFixed]: 'Whole venue at a fixed price',
  [VenuePricingType.VenuePerHour]: 'Whole venue at an hourly price',
  [VenuePricingType.HeadFixed]: 'Per person at a fixed price',
  [VenuePricingType.HeadVariable]: 'Per person at an hourly price',
};

export default function VenuePrices({
  prices,
  setValue,
  register,
  errors,
  submitting,
}: VenuePricingProps) {
  return (
    <div className="flex flex-col">
      <Label className="px-2 py-1">Pricing</Label>
      <HelperText className="ml-2 mb-2">
        These are the different price tiers this venue provides.
      </HelperText>
      <div className="flex flex-col px-8">
        {prices.map((pricing: VenuePricing, index: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`pricing.${index}`}>
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                  <Label htmlFor={`${index}.type`} className="px-2 py-1">
                    Type
                  </Label>
                  <div className="h-full items-center justify-end flex">
                    <CloseOutlineIcon
                      className="size-6 hover:cursor-pointer text-failure stroke-failure"
                      onClick={() => {
                        setValue(prices.filter((_, i) => i !== index));
                      }}
                    />
                  </div>
                </div>
                <HelperText className="ml-2 mb-2">
                  The pricing type of the Venue.
                </HelperText>
                <Select
                  id={`${index}.type`}
                  disabled={submitting}
                  required
                  {...register(`${index}.type`)}
                >
                  {Object.keys(VenuePricingType).map((key) => (
                    <option value={key} key={key}>
                      {key} -{' '}
                      {
                        VenuePricingTypeDescriptions[
                          VenuePricingType[key as keyof typeof VenuePricingType]
                        ]
                      }
                    </option>
                  ))}
                </Select>
              </div>
              {pricing.type && (
                <>
                  <div className="flex flex-col">
                    <Label htmlFor="pricing.price" className="px-2 py-1">
                      Price <span className="text-failure">*</span>
                    </Label>
                    <HelperText className="ml-2 mb-2">
                      The price of the Venue.
                    </HelperText>
                    <TextInput
                      id={`${index}.price`}
                      type="number"
                      inputMode="decimal"
                      placeholder="0"
                      disabled={submitting}
                      {...register(`${index}.price`)}
                      helperText={
                        errors?.[index]?.price?.message && (
                          <span className="text-failure ml-3">
                            {errors?.[index]?.price?.message}
                          </span>
                        )
                      }
                    />
                  </div>

                  <div className="flex flex-col">
                    <Label
                      htmlFor={`${index}.description`}
                      className="px-2 py-1"
                    >
                      Pricing Description
                    </Label>
                    <Textarea
                      id={`${index}.description`}
                      placeholder="Venue's Pricing Description"
                      rows={3}
                      disabled={submitting}
                      {...register(`${index}.description`)}
                      helperText={
                        errors?.[index]?.description && (
                          <span className="text-failure ml-3">
                            {errors?.[index]?.description?.message}
                          </span>
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
        <Button
          color="primary"
          onClick={() => {
            setValue([
              ...prices,
              {
                type: VenuePricingType.VenueFixed,
                price: 0,
                description: '',
              },
            ]);
          }}
          className="w-full mt-2 flex items-center justify-center gap-x-2"
          icon={<PlusIcon />}
        >
          Add Price
        </Button>
      </div>
    </div>
  );
}
