import {
  ChartGraph,
  Dropdown,
  LineChart,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Series } from '@cg/module-frontend/src/components/chartboard/charts/LineChart.tsx';
import Analytic from '~/pages/analytics/components/Analytic.tsx';

const repeatAttendees = (tickets: number, timeWindow: number) => `
SELECT total, tickets, timeWindow, DATE_FORMAT(dateCreated, '%Y-%m-%d') AS dateCreated
FROM analytics_repeat_attendee
WHERE timeWindow = ${timeWindow}
  AND tickets = ${tickets}
  AND dateCreated >= DATE_SUB(CURDATE(), INTERVAL 30 DAY)
`;

type Combo = {
  tickets: number;
  timeWindow: number;
};

const COMBOS: Combo[] = [
  { tickets: 2, timeWindow: 14 },

  { tickets: 2, timeWindow: 30 },
  { tickets: 3, timeWindow: 30 },

  { tickets: 2, timeWindow: 60 },
  { tickets: 3, timeWindow: 60 },
  { tickets: 5, timeWindow: 60 },
  { tickets: 7, timeWindow: 60 },
  { tickets: 10, timeWindow: 60 },

  { tickets: 2, timeWindow: 90 },
  { tickets: 3, timeWindow: 90 },
  { tickets: 5, timeWindow: 90 },
  { tickets: 7, timeWindow: 90 },
  { tickets: 10, timeWindow: 90 },
  { tickets: 15, timeWindow: 90 },

  { tickets: 2, timeWindow: 120 },
  { tickets: 3, timeWindow: 120 },
  { tickets: 5, timeWindow: 120 },
  { tickets: 7, timeWindow: 120 },
  { tickets: 10, timeWindow: 120 },
  { tickets: 15, timeWindow: 120 },
  { tickets: 20, timeWindow: 120 },

  { tickets: 2, timeWindow: 180 },
  { tickets: 3, timeWindow: 180 },
  { tickets: 5, timeWindow: 180 },
  { tickets: 7, timeWindow: 180 },
  { tickets: 10, timeWindow: 180 },
  { tickets: 15, timeWindow: 180 },
  { tickets: 20, timeWindow: 180 },

  { tickets: 2, timeWindow: 270 },
  { tickets: 3, timeWindow: 270 },
  { tickets: 5, timeWindow: 270 },
  { tickets: 7, timeWindow: 270 },
  { tickets: 10, timeWindow: 270 },
  { tickets: 15, timeWindow: 270 },
  { tickets: 20, timeWindow: 270 },

  { tickets: 2, timeWindow: 365 },
  { tickets: 3, timeWindow: 365 },
  { tickets: 5, timeWindow: 365 },
  { tickets: 7, timeWindow: 365 },
  { tickets: 10, timeWindow: 365 },
  { tickets: 15, timeWindow: 365 },
  { tickets: 20, timeWindow: 365 },
  { tickets: 30, timeWindow: 365 },
];

export default function RepeatAttendees() {
  const [label, setLabel] = React.useState('2 Tickets in 30 Days');
  const [combo, setCombo] = React.useState<Combo>({
    tickets: 2,
    timeWindow: 30,
  });

  return (
    <Analytic
      filters={
        <div key="private-only">
          <Dropdown label={label} color="grey">
            {COMBOS.map(({ tickets, timeWindow }) => {
              return (
                <Dropdown.Item
                  className="last:focus:rounded-t"
                  onClick={() => {
                    setLabel(`${tickets} Tickets in ${timeWindow} Days`);
                    setCombo({ tickets, timeWindow });
                  }}
                >
                  {tickets} tickets in {timeWindow} days
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </div>
      }
      description="Shows the list of repeat attendees defined as guests who have bought at least T tickets in the past D days."
      query={repeatAttendees(combo.tickets, combo.timeWindow).trim()}
    >
      {({ result }) => {
        if (!result || !Array.isArray(result)) {
          return null;
        }

        const x = result.map((q) => q.dateCreated);
        const y = result.map((q) => q.total);
        const series: Series = { data: { x, y }, type: 'area' };

        return (
          <ChartGraph
            key="graph"
            timelineDays={[7, 30, 90, 365]}
            hideDaySelector
          >
            <LineChart series={[series]} color="#D8607A" precision={0} />
          </ChartGraph>
        );
      }}
    </Analytic>
  );
}

RepeatAttendees.title = 'Repeat Attendees';
