import { Content, Page, Section } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { toast } from '@cg/module-frontend';
import { Venue } from '~/generated/models/Venue';
import { UpdateVenueRequest } from '~/generated/models/UpdateVenueRequest';
import { updateVenueHook } from '~/generated/clients/background/venues/BackgroundVenues.hooks.ts';
import VenueEditor from '../components/VenueEditor';

type VenuePageProps = {
  venue: Venue;
};
export default function VenuePage({ venue }: VenuePageProps) {
  const { calling: updating, call: update, error } = updateVenueHook(false);

  const onSubmit = async (body: UpdateVenueRequest) => {
    await update({
      ids: {
        venueId: venue.id,
      },
      body,
    });
    if (error) {
      toast.error(error.message);
      return;
    }
    toast.success('Successfully updated venue!');
  };

  return (
    <Page>
      <Section>
        <h2>{venue.name}</h2>

        <Content className="mt-4">
          <VenueEditor
            submitting={updating}
            onUpdate={onSubmit}
            venue={venue}
          />
        </Content>
      </Section>
    </Page>
  );
}
