import {
  Article,
  CopyIconClipboard,
  Table,
  TableHeaderItem,
  TableRowItem,
  Card,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { toCurrency } from '@cg/common/src/experiences/currency.ts';
import { TicketTier } from '~/generated/models/TicketTier';

type TierDetailsProps = {
  tiers: TicketTier[];
};
export default function TiersDetails({ tiers }: TierDetailsProps) {
  const headers: TableHeaderItem[] = [
    'Name',
    'Description',
    'Sold / Count',
    'Price',
    'Stripe ID',
  ];
  const rows: TableRowItem[] = tiers.map((tier) => {
    return {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: tier.id.getValue(),
      cells: [
        <CopyIconClipboard
          text={tier.id.getValue()}
          hideIcon
          message="ID copied to clipboard"
        >
          {tier.name}
        </CopyIconClipboard>,
        tier.description,
        `${tier.sold} / ${tier.count}`,
        toCurrency(tier.price, true),
        tier.stripePriceId,
      ],
    };
  });

  return (
    <Article>
      <Card>
        <h2>Ticket Tiers</h2>
        <Table headers={headers} rows={rows} />
      </Card>
    </Article>
  );
}
