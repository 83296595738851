/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { assignRole, AssignRoleArgs, disableUserFeature, DisableUserFeatureArgs, enableUserFeature, EnableUserFeatureArgs, fetchUser, FetchUserArgs, fetchUserExperiences, FetchUserExperiencesArgs, fetchUserRoles, FetchUserRolesArgs, listUserFeatures, ListUserFeaturesArgs, listUsers, ListUsersArgs, unAssignRole, UnAssignRoleArgs } from './BackgroundUsers.client';

export const assignRoleHook = (immediateCall: boolean = true, initArgs?: AssignRoleArgs) => {
  return useHttp(assignRole, { immediateCall, initArgs })
};
export const disableUserFeatureHook = (immediateCall: boolean = true, initArgs?: DisableUserFeatureArgs) => {
  return useHttp(disableUserFeature, { immediateCall, initArgs })
};
export const enableUserFeatureHook = (immediateCall: boolean = true, initArgs?: EnableUserFeatureArgs) => {
  return useHttp(enableUserFeature, { immediateCall, initArgs })
};
export const fetchUserHook = (immediateCall: boolean = true, initArgs?: FetchUserArgs) => {
  return useHttp(fetchUser, { immediateCall, initArgs })
};
export const fetchUserExperiencesHook = (immediateCall: boolean = true, initArgs?: FetchUserExperiencesArgs) => {
  return useHttp(fetchUserExperiences, { immediateCall, initArgs })
};
export const fetchUserRolesHook = (immediateCall: boolean = true, initArgs?: FetchUserRolesArgs) => {
  return useHttp(fetchUserRoles, { immediateCall, initArgs })
};
export const listUserFeaturesHook = (immediateCall: boolean = true, initArgs?: ListUserFeaturesArgs) => {
  return useHttp(listUserFeatures, { immediateCall, initArgs })
};
export const listUsersHook = (immediateCall: boolean = true, initArgs?: ListUsersArgs) => {
  return useHttp(listUsers, { immediateCall, initArgs })
};
export const unAssignRoleHook = (immediateCall: boolean = true, initArgs?: UnAssignRoleArgs) => {
  return useHttp(unAssignRole, { immediateCall, initArgs })
};
