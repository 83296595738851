import {
  Button,
  UserAvatar,
  Label,
  Modal,
  Spinner,
  TextInput,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useState } from 'react';
import { isValidEmail } from '@cg/common/src/utils';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import classNames from 'classnames';
import { UserId } from '@cg/common/src/ids';
import { listUsers } from '~/generated/clients/background/users/BackgroundUsers.client';
import { changeHostOwnerHook } from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import { User } from '~/generated/models/User';

type Form = {
  email: string;
};

type AddHostUserProps = {
  onClose: () => void;
  onSave: (userId: UserId) => void;
};

enum SearchingState {
  'Searching' = 'Searching',
  'Error' = 'Error',
  'Done' = 'Done',
}

export default function AddHostUser({ onSave, onClose }: AddHostUserProps) {
  const [newUser, setNewUser] = useState<User | undefined>();
  const [searching, setSearching] = useState<SearchingState>(
    SearchingState.Done,
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Form>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const { calling: changing } = changeHostOwnerHook(false);

  const onSubmit: SubmitHandler<Form> = async () => {
    if (newUser?.id) {
      onSave(newUser.id);
    }
  };

  return (
    <Modal show onClose={onClose}>
      <Modal.Header>Add Organizer</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Label htmlFor="ownerEmail" className="px-2 py-1" required>
            Email
          </Label>
          <TextInput
            id="ownerEmail"
            disabled={searching === SearchingState.Searching}
            placeholder="Owner's Email Address"
            {...register('email', {
              validate: async (email: string) => {
                if (searching === SearchingState.Searching) {
                  return 'Validating...';
                }

                if (!isValidEmail(email)) {
                  return 'Invalid email address';
                }

                setSearching(SearchingState.Searching);
                try {
                  const response = await listUsers({
                    filters: { email },
                  });

                  if (!response.succeeded) {
                    setSearching(SearchingState.Error);
                    return response.payload.message;
                  }

                  if (response.payload.result.length > 1) {
                    setSearching(SearchingState.Error);
                    return 'Multiple users found';
                  }

                  const match = response.payload.result[0];
                  if (!match) {
                    setSearching(SearchingState.Error);
                    return 'User not found';
                  }

                  setSearching(SearchingState.Done);
                  setNewUser(match);

                  return true;
                } catch (e) {
                  // @ts-ignore
                  return e.message;
                }
              },
            })}
            helperText={
              errors.email?.message ? (
                <span className="text-failure ml-3">
                  {errors.email?.message}
                </span>
              ) : null
            }
          />
        </div>

        <div
          className={classNames('flex flex-col mt-4 px-2 relative', {
            'cursor-not-allowed': searching === SearchingState.Searching,
          })}
        >
          {changing && <Spinner className="absolute top-1/2 left-1/2" />}
          <div
            className={classNames({
              'opacity-50': searching !== SearchingState.Done,
            })}
          >
            <h3>New User</h3>
            {newUser && (
              <div className="flex justify-between">
                <div>
                  <div>
                    {newUser.firstName} {newUser.lastName}
                  </div>
                  <div>{newUser.email}</div>
                  <div>{newUser.phoneNumber}</div>
                </div>
                <UserAvatar user={newUser} />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          className="ml-auto"
          disabled={!isValid || changing}
          onClick={handleSubmit(onSubmit)}
        >
          Add Organizer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
