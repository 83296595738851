import {
  Loader,
  Page,
  Section,
  Checkbox,
  Article,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import {
  ChartBoard,
  ChartCard,
  ChartCards,
  ChartGraph,
  LineChart,
} from '@cg/module-frontend/src/components/chartboard';
import { useEffect } from 'react';
import { Series } from '@cg/module-frontend/src/components/chartboard/charts/LineChart';
import { UserStatus } from '~/generated/models/UserStatus';
import { GraphData } from '~/generated/models/GraphData';
import {
  countUsersHook,
  graphUserCountHook,
} from '~/generated/clients/background/aggregates/users/BackgroundAggregatesUsers.hooks';
import {
  calculateEMA,
  calculateTrend,
  getTargetUserGrowth,
} from '~/utils/targets';

type Graph = {
  name: string;
  data: GraphData;
};

export default function UsersDashboard() {
  const { data, calling } = countUsersHook();

  const [asRate, setAsRate] = React.useState(false);
  const [activeTitle, setActiveTitle] = React.useState('Users');
  const [target, setTarget] = React.useState<Graph | null>(null);
  const [graph, setGraph] = React.useState<Graph | null>(null);
  const [derivative, setDerivative] = React.useState<Graph | null>(null);
  const [trend, setTrend] = React.useState<Graph | null>(null);
  const [ema, setEma] = React.useState<Graph | null>(null);

  const {
    data: userGraphData,
    call: fetchUserGraph,
    calling: fetchingUserGraph,
  } = graphUserCountHook(false);

  useEffect(() => {
    if (!userGraphData) {
      return;
    }
    const xData = userGraphData.x.map((x) =>
      DateUtils.dater(x).format('YYYY-MM-DD'),
    );
    setGraph({ name: 'Data', data: { x: xData, y: userGraphData.y } });
  }, [userGraphData]);

  useEffect(() => {
    if (!graph) {
      return;
    }

    setTarget(null);
    const x = graph.data.x.slice(1);
    const y = graph.data.y
      .slice(1)
      .map((value, index) => value - graph.data.y[index]);
    setDerivative({ name: 'Data', data: { x, y } });

    const emaY = calculateEMA(y, 30);
    const trendY = calculateTrend(x, y);
    setEma({ name: 'EMA (30 days)', data: { x, y: emaY } });
    setTrend({ name: 'Tend Line', data: { x, y: trendY } });
  }, [asRate]);

  const getLineChart = () => {
    const series: Series[] = [];
    if (asRate && derivative) {
      series.push({ ...derivative, type: 'area', name: activeTitle });
      if (trend) {
        series.push({
          ...trend,
          color: '#000',
          opacity: 0.5,
          dashed: true,
          type: 'line',
        });
      }
      if (ema) {
        series.push({
          ...ema,
          dashed: true,
          type: 'line',
        });
      }
    } else {
      if (graph) {
        series.push({ ...graph, type: 'area', name: activeTitle });
      }
      if (target) {
        series.push({ ...target, dashed: true, type: 'line' });
      }
    }

    if (series.length > 0) {
      return <LineChart series={series} color="#D8607A" precision={0} />;
    }

    return null;
  };
  const fetchingData = calling || fetchingUserGraph;

  return (
    <Page>
      <Section>
        <Article>
          <h2>Users</h2>
          <Loader loading={calling} horizontal>
            <ChartBoard
              onChange={(title) => {
                setActiveTitle(title);
                setAsRate(false);
              }}
            >
              <ChartCards key="cards">
                <ChartCard
                  title="Users"
                  value={data?.active}
                  render={async ({ days }) => {
                    const response = await fetchUserGraph({
                      filters: {
                        status: UserStatus.Active,
                        logged30Days: false,
                        lastDays: days,
                      },
                    });
                    if (response.succeeded) {
                      const today = DateUtils.dater(
                        response.payload.x[response.payload.x.length - 1],
                      ).format('YYYY-MM-DD');
                      setTarget({
                        name: 'Growth Target',
                        data: getTargetUserGrowth(
                          today,
                          response.payload.x.length,
                        ),
                      });
                    }
                  }}
                />
                <ChartCard
                  title="Signups"
                  value={data?.signup}
                  render={async ({ days }) => {
                    setTarget(null);
                    await fetchUserGraph({
                      filters: {
                        status: UserStatus.Signup,
                        logged30Days: false,
                        lastDays: days,
                      },
                    });
                  }}
                />
                <ChartCard
                  title="Phantoms"
                  value={data?.phantom}
                  render={async ({ days }) => {
                    setTarget(null);
                    await fetchUserGraph({
                      filters: {
                        status: UserStatus.Phantom,
                        logged30Days: false,
                        lastDays: days,
                      },
                    });
                  }}
                />
              </ChartCards>

              <ChartGraph
                key="graph"
                timelineDays={[7, 30, 90, 120, 180, 270, 365]}
                additionalFilters={
                  <div
                    className="flex flex-row items-center justify-center w-full"
                    key="private-only"
                  >
                    As Rate
                    <Checkbox
                      className="ml-2"
                      checked={asRate}
                      onChange={(event) => setAsRate(event.target.checked)}
                    />
                  </div>
                }
              >
                <Loader loading={fetchingData} horizontal>
                  {getLineChart()}
                </Loader>
              </ChartGraph>
            </ChartBoard>
          </Loader>
        </Article>
      </Section>
    </Page>
  );
}
