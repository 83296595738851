import { HostId } from '@cg/common/src/ids';
import { boxConfig } from '@cg/module-frontend/src/config';
import { UniversalSearcher } from './types';

const searchByHostId: UniversalSearcher = {
  is: (text: string): boolean => {
    return HostId.isValid(text);
  },
  apply: async (text: string) => {
    return {
      url: `${boxConfig.baseUrls.backGround}/hosts/${text}`,
    };
  },
};

export default searchByHostId;
