import { Page, Section } from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import { Experience } from '~/generated/models/Experience';
import { ExperienceDetails } from '~/components/experiences';
import { Host } from '~/generated/models/Host.ts';
import { UserWithTicket } from '~/generated/models/UserWithTicket.ts';
import GuestsDetails from './components/GuestsDetails.tsx';
import { TicketTier } from '~/generated/models/TicketTier.ts';
import Sales from './components/Sales';
import HostDetails from './components/HostDetails.tsx';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus.ts';
import { PaymentDetail } from '~/generated/models/PaymentDetail.ts';
import PaymentNotification from '~/pages/experiences/experience/components/PaymentNotification.tsx';
import TiersDetails from './components/TiersDetails.tsx';
import { User } from '~/generated/models/User.ts';
import AIGeneratedDetails from './components/AIGeneratedDetails';
import { ExperienceConfirmation } from '~/generated/models/ExperienceConfirmation.ts';

type ExperiencePageProps = {
  owner: User;
  experience: Experience;
  paymentDetail: PaymentDetail;
  host: Host;
  confirmation?: ExperienceConfirmation;
  users: UserWithTicket[];
  tiers: TicketTier[];
};

export default function ExperiencePage({
  owner,
  experience,
  host,
  users,
  tiers,
  confirmation,
  paymentDetail,
}: ExperiencePageProps) {
  const [unpaid, setPaid] = React.useState(
    !experience.datePaid && experience.status === ExperienceStatus.Published,
  );
  const hasPaymentAddress = Boolean(paymentDetail.address);
  const isFuture = DateUtils.isFuture(experience.endDate);

  return (
    <Page>
      <PaymentNotification
        unpaid={unpaid}
        hasPaymentAddress={hasPaymentAddress}
        isFuture={isFuture}
      />

      <Section>
        <ExperienceDetails owner={owner} host={host} experience={experience} />
      </Section>

      <Section>
        <HostDetails host={host} paymentDetail={paymentDetail} />
      </Section>

      {experience.meta?.ai && (
        <Section>
          <AIGeneratedDetails
            tiers={tiers}
            confirmation={confirmation}
            meta={experience.meta.ai}
            experience={experience}
          />
        </Section>
      )}

      <Section>
        <Sales
          hasPaymentAddress={hasPaymentAddress}
          tiers={tiers}
          users={users}
          experience={experience}
          setPaid={() => setPaid(false)}
        />
      </Section>

      <Section>
        <TiersDetails tiers={tiers} />
      </Section>

      <Section>
        <GuestsDetails tiers={tiers} users={users} />
      </Section>
    </Page>
  );
}
