import {
  Table as FlowbiteTable,
  TableProps as FlowbiteTableProps,
} from 'flowbite-react';
import React, { ReactNode, useEffect, useState } from 'react';
import TableHeader, { TableSortDirection } from './TableHeader';

type Length<T extends unknown[]> = T extends { length: infer L } ? L : never;

export type TableHeaderItem =
  | string
  | {
      label: ReactNode;
      key: string;
      filter?: (direction: TableSortDirection) => void;
    };

type CellAdvanced = {
  key?: string;
  span?: number;
  className?: string;
  label: ReactNode;
  onClick?: () => void;
};
export type TableCellItem =
  | (string | number | undefined | null | unknown)
  | CellAdvanced;

export type TableRowItem = {
  key: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  cells: TableCellItem[];
};

const isCellItem = (cell: TableCellItem): cell is CellAdvanced => {
  return typeof cell === 'object' && cell !== null && 'label' in cell;
};

type TableProps<
  Headers extends TableHeaderItem[],
  Rows extends TableRowItem[],
> =
  Length<Headers> extends Length<Rows>
    ? {
        headers: Headers;
        rows: Rows;
      } & FlowbiteTableProps
    : never;

export default function Table<
  Headers extends TableHeaderItem[],
  Rows extends TableRowItem[],
>({
  headers,
  rows,
  striped,
  hoverable,
  ...tableProps
}: TableProps<Headers, Rows>) {
  const [activeFilter, setActiveFilter] = useState<number>(-1);
  if (striped === undefined || striped === null) {
    striped = true;
  }
  if (hoverable === undefined || hoverable === null) {
    hoverable = true;
  }

  const onFilter = (
    filter: (direction: TableSortDirection) => void,
    index: number,
  ) => {
    return (direction: TableSortDirection) => {
      setActiveFilter(index);
      filter(direction);
    };
  };

  useEffect(() => {
    if (activeFilter !== -1) {
      headers.forEach((header, index) => {
        if (
          typeof header !== 'string' &&
          header.filter &&
          index !== activeFilter
        ) {
          header.filter(null);
        }
      });
    }
  }, [activeFilter]);

  return (
    <div className="overflow-x-auto">
      <FlowbiteTable
        hoverable={hoverable}
        {...tableProps}
        className="table-auto"
      >
        <FlowbiteTable.Head>
          {headers.map((header, index) => {
            if (typeof header === 'string') {
              return <TableHeader key={header}>{header}</TableHeader>;
            }

            if (header.filter) {
              return (
                <TableHeader
                  key={header.key}
                  active={activeFilter === index}
                  filter={onFilter(header.filter, index)}
                >
                  {header.label}
                </TableHeader>
              );
            }

            return (
              <TableHeader key={header.key} active={activeFilter === index}>
                {header.label}
              </TableHeader>
            );
          })}
        </FlowbiteTable.Head>
        <FlowbiteTable.Body className="divide-y">
          {rows.map((row) => (
            <FlowbiteTable.Row
              key={row.key}
              onClick={row.onClick}
              className={row.className}
            >
              {row.cells.map((cell, index) => {
                // Most of our tables have an ID that we use for row key
                // If so we can call the cell key as `header-rowId`
                const header = headers[index];
                const hKey = typeof header === 'string' ? header : header.label;
                const key = `${hKey}-${row.key}`;

                if (!cell) {
                  return <FlowbiteTable.Cell key={key} />;
                }

                if (isCellItem(cell)) {
                  return (
                    <FlowbiteTable.Cell
                      key={cell.key || key}
                      colSpan={cell.span}
                      className={cell.className}
                      onClick={cell.onClick}
                    >
                      {cell.label}
                    </FlowbiteTable.Cell>
                  );
                }

                return (
                  <FlowbiteTable.Cell key={key}>
                    {cell as unknown as string}
                  </FlowbiteTable.Cell>
                );
              })}
            </FlowbiteTable.Row>
          ))}
        </FlowbiteTable.Body>
      </FlowbiteTable>
    </div>
  );
}
