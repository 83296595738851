import { UniversalSearchBar, UniversalSearcher } from './types';
import searchByUserId from './searchByUserId';
import searchByHostId from './searchByHostId';
import searchByExperienceId from './searchByExperienceId';
import searchByTicketId from './searchByTicketId';
import searchByEmail from './searchByEmail';
import searchByName from './searchByName';
import searchByPhoneNumber from './searchByPhoneNumber';

export type { UniversalSearchBar };

const commands: UniversalSearcher[] = [
  searchByUserId,
  searchByExperienceId,
  searchByHostId,
  searchByEmail,
  searchByName,
  searchByPhoneNumber,
  searchByTicketId,
];

export default commands;
