import { Label as FBLabel, LabelProps } from 'flowbite-react';
import { ToolTip, HelperTip } from '../tooltip';

type Props = LabelProps & {
  required?: boolean;
  additionalInfo?: string;
};

export default function Label(props: Props) {
  const { required, additionalInfo, children, ...rest } = props;

  return (
    <FBLabel {...rest}>
      <span className="flex">
        <h5 className="font-semibold">{children}</h5>
        {required && (
          <ToolTip content="This field is requried">
            <span className="text-primary ml-1 text-lg font-bold">*</span>
          </ToolTip>
        )}
        {additionalInfo && <HelperTip content={additionalInfo} />}
      </span>
    </FBLabel>
  );
}
