import { TicketTierId } from '../ids';
import { toCurrency } from './currency';

type Ticket = {
  ticketTierId: TicketTierId;
};

type TicketTier = {
  id: TicketTierId;
  name: string;
  price: number;
};

export default function generateSummaryOfSales(
  tickets: Ticket[],
  tiers: TicketTier[],
) {
  const list = tiers.map((tier) => {
    const count = tickets.filter((ticket) =>
      ticket.ticketTierId.isEqual(tier.id),
    ).length;
    const totalSales = tier.price * count;

    return {
      id: tier.id,
      name: tier.name,
      price: tier.price,
      priceDollar: toCurrency(tier.price, true),
      count,
      totalSales,
      totalSalesDollar: toCurrency(totalSales, true),
    };
  });

  const grossRevenue = list.reduce((acc, tier) => acc + tier.totalSales, 0);
  const grossRevenueDollar = toCurrency(grossRevenue, true);

  const stripeFee = list.reduce((acc, tier) => {
    if (tier.price === 0) {
      return acc;
    }

    return acc + tier.count * (tier.price * 0.029 + 30);
  }, 0);
  const stripeFeeDollar = toCurrency(-1 * stripeFee, true);

  const netRevenue = grossRevenue - stripeFee;
  const netRevenueDollar = toCurrency(netRevenue, true);

  return {
    list,
    grossRevenueDollar,
    netRevenueDollar,
    stripeFeeDollar,
  };
}
