import { TicketTierId } from '../ids';

type ExperienceWithTickets = {
  tickets: Array<{
    ticketTierId: TicketTierId;
  }>;
  tiers: Array<{
    id: TicketTierId;
    price?: number;
  }>;
};

export const toCurrency = (money: number, isCent?: boolean) => {
  const divider = isCent ? 100 : 1;
  const amount = money / divider;
  if (amount === 0) {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(0);
  }

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(amount);
};

/**
 * Calculate the revenue of an experience
 * @param experience
 * @param asNumber
 */
export const calculateRevenue = (
  experience: ExperienceWithTickets,
  asNumber = false,
): string | number => {
  const money = experience.tickets.reduce((total, ticket) => {
    const tier = experience.tiers.find((t) =>
      t.id.isEqual(ticket.ticketTierId),
    );
    return total + (tier?.price || 0) / 100;
  }, 0);

  if (asNumber) {
    return money;
  }

  return toCurrency(money, false);
};
