import * as React from 'react';
import { useNavigate } from 'react-router';
import { ExperienceCount } from '~/generated/models/ExperienceCount.ts';
import { UserCount } from '~/generated/models/UserCount.ts';
import { TicketCount } from '~/generated/models/TicketCount.ts';
import DashboardCard from './components/DashboardCard.tsx';

type Props = {
  experiencesCount: ExperienceCount;
  usersCount: UserCount;
  ticketsCount: TicketCount;
};

export default function DashboardPage({
  experiencesCount,
  ticketsCount,
  usersCount,
}: Props) {
  const navigate = useNavigate();
  return (
    <>
      <DashboardCard
        title="Users"
        value={usersCount.active}
        onClick={() => navigate('/users')}
      />
      <DashboardCard
        title="Tickets"
        value={ticketsCount.all}
        onClick={() => navigate('/tickets')}
      />
      <DashboardCard
        title="Experiences"
        value={experiencesCount.all}
        onClick={() => navigate('/experiences')}
      />
    </>
  );
}
