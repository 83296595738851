import * as DateUtils from '@cg/common/src/utils/DateUtils';

const WEEKLY_GROWTH_USER = 0.1;
const userGrowthRate = (1 + WEEKLY_GROWTH_USER) ** (1 / 7);

export const getTargetUserGrowth = (today: string, days: number) => {
  const launchDate = '2024-03-21';
  const startUsers = 3;
  const totalDays =
    DateUtils.dayjs(today).diff(DateUtils.dayjs(launchDate), 'days') + 1;

  const users = Array.from(
    { length: totalDays },
    (_, i) => startUsers * userGrowthRate ** i,
  );

  const series = {
    x: Array.from({ length: totalDays }, (_, i) =>
      DateUtils.dayjs(launchDate).add(i, 'days').format('YYYY-MM-DD'),
    ),
    y: users,
  };

  // fill series.x until today is reached

  if (days < totalDays) {
    series.x = series.x.slice(totalDays - days);
    series.y = series.y.slice(totalDays - days);
  }

  return series;
};

export const calculateEMA = (y: number[], period: number) => {
  const k = 2 / (period + 1);
  const emaArray = [y[0]];
  // eslint-disable-next-line
  for (let i = 1; i < y.length; i++) {
    emaArray.push(y[i] * k + emaArray[i - 1] * (1 - k));
  }
  return emaArray;
};

export const calculateTrend = (x: string[], y: number[]) => {
  const n = x.length;
  let sumIndex = 0;
  let sumY = 0;
  let sumIndexY = 0;
  let sumIndex2 = 0;

  // eslint-disable-next-line
  for (let i = 0; i < n; i++) {
    sumIndex += i;
    sumY += y[i];
    sumIndexY += i * y[i];
    sumIndex2 += i * i;
  }

  const slope =
    (n * sumIndexY - sumIndex * sumY) / (n * sumIndex2 - sumIndex * sumIndex);
  const intercept = (sumY - slope * sumIndex) / n;

  return x.map((_, i) => slope * i + intercept);
};
