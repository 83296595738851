import {
  Article,
  Button,
  Loader,
  Page,
  Section,
  Table,
  TableRowItem,
  Checkbox,
} from '@cg/module-frontend/src/components';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import React, { useEffect, useState } from 'react';
import { toast } from '@cg/module-frontend';
import {
  listFeatureFlagsHook,
  updateFeatureFlagHook,
} from '~/generated/clients/background/features/BackgroundFeatures.hooks';
import NewFeatureFlag from '~/pages/features/components/NewFeatureFlag';
import { FeatureFlag } from '~/generated/models/FeatureFlag';

function FeaturesPage() {
  const [isOpen, setIsOpen] = useState(false);
  const args = {
    filters: { pageSize: 999 },
  };
  const {
    data: flags,
    calling: fetchingFlags,
    call: fetchFlags,
  } = listFeatureFlagsHook(true, { ...args });
  const {
    call: toggleFlag,
    calling: togglingFlag,
    error: toggleFlagError,
  } = updateFeatureFlagHook(false);

  useEffect(() => {
    if (toggleFlagError) {
      toast.error(toggleFlagError?.message);
    }
  }, [toggleFlagError]);

  const toggle = async (flag: FeatureFlag) => {
    await toggleFlag({
      ids: {
        featureFlagId: flag.id,
      },
      body: {
        enabled: !flag.enabled,
      },
    });
  };

  const headers = ['Name', 'Description', 'Enabled'];
  const rows: TableRowItem[] =
    flags?.result?.map((flag) => {
      const sixMonthsAgo = DateUtils.dayjs().subtract(6, 'months');
      const oldAndEnabled =
        flag.enabled &&
        DateUtils.dater(flag.dateUpdated).isBefore(sixMonthsAgo);
      const oldAndDisabled =
        !flag.enabled &&
        DateUtils.dater(flag.dateCreated).isBefore(sixMonthsAgo);

      return {
        className: 'bg-white border-grey-darker cursor-pointer',
        key: flag.id.getValue(),
        cells: [
          <>
            {flag.feature}
            {oldAndEnabled && (
              <div className="text-warning">
                This is feature has been enabled for 6 months. Consider deleting
                it.
              </div>
            )}
            {oldAndDisabled && (
              <div className="text-failure">
                This is feature has been created for more than 6 months and has
                not been enabled.
              </div>
            )}
          </>,
          flag.description,
          <Checkbox
            onClick={() => toggle(flag)}
            defaultChecked={flag.enabled}
            disabled={togglingFlag}
          />,
        ],
      };
    }) || [];

  return (
    <Page>
      <Section>
        {isOpen && (
          <NewFeatureFlag
            refetch={() => fetchFlags({ ...args })}
            close={() => setIsOpen(false)}
          />
        )}

        <Article>
          <div className="flex justify-between items-center ">
            <h2>Feature Flags</h2>
            <div className="flex space-x-4">
              <Button color="primary" onClick={() => setIsOpen(true)}>
                Create Flag
              </Button>
            </div>
          </div>

          <p className="flex">
            <span className="text-warning">
              <strong>Warning: </strong>
              Toggling the check boxes will automatically enable/disable the
              feature!
            </span>
          </p>
          <Loader loading={fetchingFlags} horizontal>
            <div className="relative w-full">
              <Loader
                loading={togglingFlag}
                horizontal
                className="absolute top-0 left-0 w-10 h-20 flex justify-center items-center z-0"
              />
              <Table headers={headers} rows={rows} />
            </div>
          </Loader>
        </Article>
      </Section>
    </Page>
  );
}

export default FeaturesPage;
