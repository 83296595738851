import { mkConfig, download, generateCsv } from 'export-to-csv';

type Data = {
  [key: string]: string | number | boolean | undefined | null | unknown;
};

/**
 * Downloads the given data as CSV
 * @param data
 */
export const downloadCsv = (data: Data[]) => {
  const csvConfig = mkConfig({ useKeysAsHeaders: true });
  // @ts-ignore
  const csv = generateCsv(csvConfig)(data);

  download(csvConfig)(csv);
};
