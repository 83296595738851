import { Loader, Page, Section } from '@cg/module-frontend/src/components';
import React from 'react';
import {
  fetchUserExperiencesHook,
  listUserFeaturesHook,
} from '~/generated/clients/background/users/BackgroundUsers.hooks';
import { User } from '~/generated/models/User';
import { UserDetails } from '~/components/users';
import UserFlags from './components/UserFlags';
import UserExperiences from './components/UserExperiences';

type UserPageProps = {
  user: User;
};

function UserPage({ user }: UserPageProps) {
  const { data: flags, calling: fetchingFlags } = listUserFeaturesHook(true, {
    ids: {
      userId: user.id,
    },
  });

  const { data: upcomingExperiences, calling: fetchingUpcomingExperiences } =
    fetchUserExperiencesHook(true, {
      ids: { userId: user.id },
      filters: { pageSize: 25 },
    });
  const { data: previousExperiences, calling: fetchingPrevExperiences } =
    fetchUserExperiencesHook(true, {
      ids: { userId: user.id },
      filters: { pageSize: 25, previousOnly: true },
    });

  return (
    <Page>
      <Section>
        <UserDetails user={user} />
      </Section>

      <Loader loading={fetchingFlags} horizontal>
        <Section>
          <UserFlags flags={flags ?? []} />
        </Section>
      </Loader>

      <Loader
        loading={fetchingUpcomingExperiences || fetchingPrevExperiences}
        horizontal
      >
        <Section>
          <UserExperiences
            upcomingExperiences={upcomingExperiences?.result ?? []}
            previousExperiences={previousExperiences?.result ?? []}
          />
        </Section>
      </Loader>
    </Page>
  );
}

export default UserPage;
