import {
  Accordion,
  Article,
  Card,
  FBTabs,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { CodeMirrorDiff } from '@cg/module-frontend/src/components/code-editor';
import { isEqual, prettyJSON } from '@cg/common/src/utils';
import { ExperienceMetaAI } from '~/generated/models/ExperienceMetaAI.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { ExperienceConfirmation } from '~/generated/models/ExperienceConfirmation.ts';
import { TicketTier } from '~/generated/models/TicketTier.ts';

type AIGeneratedDetailsProps = {
  experience: Experience;
  confirmation?: ExperienceConfirmation;
  tiers: TicketTier[];
  meta: ExperienceMetaAI;
};

type Diff = {
  name: string;
  ai: unknown;
  human: unknown;
};

const eFields: (keyof Experience)[] = ['title', 'uniqueName', 'content'];
const cFields: (keyof ExperienceConfirmation)[] = ['text'];

export default function AIGeneratedDetails({
  meta,
  experience,
  confirmation,
  tiers,
}: AIGeneratedDetailsProps) {
  const diff: Diff[] = [];
  eFields.forEach((field) => {
    if (meta.original.experience[field] !== experience[field]) {
      diff.push({
        name: field,
        ai: meta.original.experience[field],
        human: experience[field],
      });
    }
  });
  cFields.forEach((field) => {
    if (
      confirmation &&
      meta.original.confirmation[field] !== confirmation[field]
    ) {
      diff.push({
        name: field,
        ai: meta.original.confirmation[field],
        human: confirmation[field],
      });
    }
  });
  tiers.forEach((tier) => {
    const match = meta.original.tiers.filter((t) => t.id.isEqual(tier.id))[0];
    if (match && !isEqual(match, tier)) {
      diff.push({
        name: `${tier.name}`,
        ai: prettyJSON(
          {
            name: match.name,
            description: match.description,
            count: match.count,
            price: match.price,
          },
          false,
        ),
        human: prettyJSON(
          {
            name: tier.name,
            description: tier.description,
            count: tier.count,
            price: tier.price,
          },
          false,
        ),
      });
    }
  });

  return (
    <Article>
      <Card>
        <h2>Instant AI</h2>

        <FBTabs>
          <FBTabs.Item active title="Intent">
            <div>
              <strong>Intent:</strong> {meta.original.intent.intent}
            </div>
            <div>
              <strong>Included:</strong>{' '}
              {meta.original.intent.include || 'Not Provided'}
            </div>
            <div>
              <strong>Bring:</strong>{' '}
              {meta.original.intent.bring || 'Not Provided'}
            </div>
          </FBTabs.Item>
          <FBTabs.Item title="Differences">
            <Accordion>
              {diff.map((d) => {
                return (
                  <Accordion.Panel key={d.name}>
                    <Accordion.Title>{d.name}</Accordion.Title>
                    <Accordion.Content>
                      <CodeMirrorDiff
                        original={d.ai as string}
                        modified={d.human as string}
                      />
                    </Accordion.Content>
                  </Accordion.Panel>
                );
              })}
            </Accordion>
          </FBTabs.Item>
        </FBTabs>
      </Card>
    </Article>
  );
}
