import { Section, Page } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { Ticket } from '~/generated/models/Ticket.ts';
import { User } from '~/generated/models/User.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { UserDetails } from '~/components/users';
import { Host } from '~/generated/models/Host.ts';
import { ExperienceDetails } from '~/components/experiences';
import TicketDetails from './components/TicketDetails';
import { TicketTier } from '~/generated/models/TicketTier.ts';

type TicketsPageProps = {
  ticket: Ticket;
  user: User;
  experience: Experience;
  host: Host;
  tier: TicketTier;
};

export default function TicketPage({
  tier,
  ticket,
  host,
  user,
  experience,
}: TicketsPageProps) {
  return (
    <Page>
      <Section>
        <TicketDetails tier={tier} ticket={ticket} user={user} />
      </Section>

      <Section>
        <UserDetails user={user} />
      </Section>

      <Section>
        <ExperienceDetails host={host} experience={experience} />
      </Section>
    </Page>
  );
}
