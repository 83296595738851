import {
  Button,
  Map,
  Checkbox,
  HelperText,
  Label,
  Select,
  Textarea,
  TextInput,
} from '@cg/module-frontend/src/components';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail, isValidPhone, isValidUrl } from '@cg/common/src/utils';
import AddressField, {
  EditableAddress,
} from '@cg/module-frontend/src/components/editable-field/AddressField';
import React, { useEffect, useState } from 'react';
import { Venue } from '~/generated/models/Venue';
import { UpdateVenueRequest } from '~/generated/models/UpdateVenueRequest';
import { VenueCategory } from '~/generated/models/VenueCategory';
import { CreateVenueRequest } from '~/generated/models/CreateVenueRequest.ts';
import { VenuePricingType } from '~/generated/models/VenuePricingType';
import { Address } from '~/generated/models/Address';
import {
  updateAddressHook,
  fetchAddressHook,
} from '~/generated/clients/background/venues/BackgroundVenues.hooks';
import Uploader from './Uploader';
import { VenuePricing } from '~/generated/models/VenuePricing';
import VenueHOP, { DayOfWeek } from '~/pages/venues/components/VenueHOP.tsx';
import VenuePrices from '~/pages/venues/components/VenuePrices.tsx';

const updateLink = (e: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = e.target.value;
  return inputValue.startsWith('http://') || inputValue.startsWith('https://')
    ? inputValue
    : `https://${inputValue}`;
};
type VenuePageProps = {
  venue?: Venue;
  submitting: boolean;
  onUpdate?: (data: UpdateVenueRequest) => void;
  onCreate?: (data: CreateVenueRequest) => void;
};

export default function VenueEditor({
  venue,
  onUpdate,
  onCreate,
  submitting,
}: VenuePageProps) {
  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateVenueRequest | CreateVenueRequest>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      bannerUrl: '',
      description: '',
      category: VenueCategory.Fitness,
      hours: {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
        holidays: [],
      },
      capacity: '',
      pricing: [
        {
          type: VenuePricingType.VenueFixed,
          price: 0,
          description: '',
        },
      ],
      email: '',
      phoneNumber: '',
      website: '',
      details: {
        liquorLicense: false,
        googleReviewLink: '',
        uniqueSellingPoint: '',
      },
      ...venue,
    },
  });

  const { data: fetchedAddress, call: fetchAddress } = fetchAddressHook(false);

  useEffect(() => {
    if (venue) {
      fetchAddress({ ids: { venueId: venue.id } });
    }
  }, [venue]);

  const { call: updateAddress, error: addressError } = updateAddressHook(false);
  const [address, setAddress] = useState<Address | null>(fetchedAddress);
  useEffect(() => {
    if (fetchedAddress) {
      setAddress(fetchedAddress);
    }
  }, [fetchedAddress]);

  const submit: SubmitHandler<UpdateVenueRequest | CreateVenueRequest> = async (
    data,
  ) => {
    const body = { ...data };
    Object.keys(body).forEach((key) => {
      // @ts-ignore
      if (body[key] === '' || !body[key]) {
        // @ts-ignore
        delete body[key];
      }
    });

    if (onUpdate) {
      onUpdate(body as UpdateVenueRequest);
      if (venue && address) {
        updateAddress({
          ids: { venueId: venue.id },
          body: address,
        });
      }
    }
    if (onCreate) {
      onCreate({
        ...body,
        address,
      } as CreateVenueRequest);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-4">
      {venue?.id && onUpdate && (
        <div className="flex flex-col" key="image">
          <Label htmlFor="bannerUrl" className="px-2" required>
            Image
          </Label>
          <Uploader
            venueId={venue.id}
            getValues={getValues}
            setValue={(value) => setValue('bannerUrl', value)}
            className="max-w-[585px]"
          />
        </div>
      )}

      <div className="flex flex-col" key="name">
        <Label htmlFor="name" className="px-2" required>
          Name
        </Label>
        <HelperText className="ml-2 mb-2">
          The official name of this Venue.
        </HelperText>
        <TextInput
          id="name"
          placeholder="Venue's Name"
          {...register('name')}
          disabled={submitting}
          helperText={
            errors.name?.message ? (
              <span className="text-failure ml-3">{errors.name?.message}</span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col">
        <Label htmlFor="description" className="px-2" required>
          Description
        </Label>
        <HelperText className="ml-2 mb-2">
          The description of the Venue. Be as descriptive as you can. This
          should include everything else that you do not find in this form.
        </HelperText>
        <Textarea
          id="description"
          placeholder="Venue's Description"
          rows={3}
          disabled={submitting}
          {...register('description')}
          helperText={
            errors.description?.message ? (
              <span className="text-failure ml-3">
                {errors.description?.message}
              </span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col">
        <Label htmlFor="category" className="px-2" required>
          Category
        </Label>
        <HelperText className="ml-2 mb-2">
          The category of the Venue. If you require a new one, contact Kousha.
        </HelperText>
        <Select
          id="category"
          {...register('category')}
          defaultValue={watch('category')}
          disabled={submitting}
        >
          {Object.keys(VenueCategory).map((key) => {
            return (
              <option key={key} value={key}>
                {key}
              </option>
            );
          })}
        </Select>
      </div>

      <div className="flex flex-col">
        <Label htmlFor="capacity" className="px-2" required>
          Capacity
        </Label>
        <HelperText className="ml-2 mb-2">
          The capacity of the Venue. If there are different types of capacity,
          include them. For example: <br />
          <span className="italic">
            Seated capacity is 100. Standing capacity is 200.
          </span>
        </HelperText>
        <Textarea
          id="capacity"
          placeholder="Venue's Capacity Description"
          rows={3}
          disabled={submitting}
          {...register('capacity')}
          helperText={
            errors.capacity?.message ? (
              <span className="text-failure ml-3">
                {errors.capacity?.message}
              </span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col">
        <Label htmlFor="address" className="px-2">
          Venue Address
        </Label>
        <HelperText className="ml-2 mb-2">The address of the Venue.</HelperText>
        <div className="px-2space-y-4">
          {address && (
            <Map
              latitude={address.latitude}
              secret={false}
              longitude={address.longitude}
              address={address}
            />
          )}
          <AddressField
            value={address as EditableAddress}
            setValue={(v: EditableAddress) => setAddress(v as Address)}
            save={() => {}}
            edit
            hideName
          />
          {addressError && (
            <span className="text-failure ml-3">{addressError.message}</span>
          )}
        </div>
      </div>

      <VenueHOP
        hours={watch('hours')}
        submitting={submitting}
        // @ts-ignore
        setValue={(key: DayOfWeek, value: string | HolidayHoursOfOperation[]) =>
          setValue(`hours.${key}`, value)
        }
        // @ts-ignore
        register={(name: DayOfWeek, options) =>
          // @ts-ignore
          register(`hours.${name}`, options)
        }
        errors={errors?.hours}
      />

      <VenuePrices
        prices={watch('pricing') || []}
        submitting={submitting}
        setValue={(value: VenuePricing[]) => setValue('pricing', value)}
        // @ts-ignore
        register={(name, options) =>
          // @ts-ignore
          register(`pricing.${name}`, options)
        }
        // @ts-ignore
        errors={errors?.pricing || []}
      />

      <div className="flex flex-col" key="email">
        <Label htmlFor="email" className="px-2">
          Email
        </Label>
        <HelperText className="ml-2 mb-2">
          The email contact of this venue
        </HelperText>
        <TextInput
          id="email"
          placeholder="The email of the venue"
          disabled={submitting}
          {...register('email', {
            validate: (email: string | undefined) => {
              if (!email) {
                return true;
              }

              if (!isValidEmail(email)) {
                return 'Email is not valid';
              }

              return true;
            },
          })}
          helperText={
            errors.email?.message ? (
              <span className="text-failure ml-3">{errors.email?.message}</span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col" key="phone-number">
        <Label htmlFor="phoneNumber" className="px-2">
          Phone Number
        </Label>
        <HelperText className="ml-2 mb-2">
          The Phone Number contact of this venue
        </HelperText>
        <TextInput
          id="phoneNumber"
          placeholder="The phone number of the venue"
          disabled={submitting}
          {...register('phoneNumber', {
            validate: (phoneNumber: string | undefined) => {
              if (!phoneNumber) {
                return true;
              }

              if (!isValidPhone(phoneNumber)) {
                return 'Phone number is not valid';
              }

              return true;
            },
          })}
          helperText={
            errors.phoneNumber?.message ? (
              <span className="text-failure ml-3">
                {errors.phoneNumber?.message}
              </span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col" key="website">
        <Label htmlFor="website" className="px-2">
          Website
        </Label>
        <HelperText className="ml-2 mb-2">
          The website of this Venue.
        </HelperText>
        <TextInput
          id="website"
          placeholder="The website of the Venue"
          disabled={submitting}
          {...register('website', {
            onChange: (e) => {
              setValue('website', updateLink(e));
            },
            validate: (website: string | undefined) => {
              if (!website) {
                return true;
              }

              if (!isValidUrl(website)) {
                return 'Website is not valid';
              }

              return true;
            },
          })}
          helperText={
            errors.website?.message ? (
              <span className="text-failure ml-3">
                {errors.website?.message}
              </span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col" key="liquor-license">
        <Label className="px-2 py-1">Liquor License</Label>
        <div className="flex items-center gap-2">
          <Checkbox
            id="details.liquorLicense"
            disabled={submitting}
            {...register('details.liquorLicense')}
          />
          <Label htmlFor="details.liquorLicense" className="ml-2">
            Venue requires Special Event liquor licence.
          </Label>
        </div>
      </div>

      {venue?.id && getValues('category') === VenueCategory.Fitness && (
        <div className="flex flex-col">
          <Label htmlFor="details.freeTrialDescription" className="px-2 py-1">
            Free Trial
          </Label>
          <HelperText className="ml-2 mb-2">
            The free trial description of this Venue.
          </HelperText>
          <TextInput
            id="details.freeTrialDescription"
            placeholder="The free trial description of this fitness venue."
            disabled={submitting}
            {...register('details.freeTrialDescription')}
            helperText={
              errors.details?.freeTrialDescription &&
              errors.details.freeTrialDescription?.message ? (
                <span className="text-failure ml-3">
                  {errors.details.freeTrialDescription.message}
                </span>
              ) : null
            }
          />
        </div>
      )}

      <div className="flex flex-col" key="review-link">
        <Label htmlFor="details.googleReviewLink" className="px-2 py-1">
          Google Review Link
        </Label>
        <HelperText className="ml-2 mb-2">
          The Google Review Link of this Venue.
        </HelperText>
        <TextInput
          id="details.googleReviewLink"
          placeholder="The google review link of the Venue"
          disabled={submitting}
          {...register('details.googleReviewLink', {
            onChange: (e) => {
              setValue('details.googleReviewLink', updateLink(e));
            },
            validate: (googleReviewLink: string | undefined) => {
              if (!googleReviewLink) {
                return true;
              }

              if (!isValidUrl(googleReviewLink)) {
                return 'Website is not valid';
              }

              return true;
            },
          })}
          helperText={
            errors.details?.googleReviewLink &&
            errors.details?.googleReviewLink?.message ? (
              <span className="text-failure ml-3">
                {errors.details.googleReviewLink.message}
              </span>
            ) : null
          }
        />
      </div>

      <div className="flex flex-col" key="selling-point">
        <Label htmlFor="details.uniqueSellingPoint" className="px-2 py-1">
          Unique Selling Point
        </Label>
        <Textarea
          id="details.uniqueSellingPoint"
          placeholder="The Unique Selling Point of the Venue"
          disabled={submitting}
          rows={3}
          {...register('details.uniqueSellingPoint')}
        />
      </div>

      <div className="flex justify-end mt-4">
        <Button
          disabled={!isValid}
          type="submit"
          color="primary"
          className="w-full"
        >
          Save
        </Button>
      </div>
    </form>
  );
}
