import React from 'react';
import UserPage from './UserPage';
import { getUserLoaderData } from '~/utils/loaderData.hooks';

function UserComponent() {
  const user = getUserLoaderData();

  return <UserPage user={user} />;
}

export default UserComponent;
