import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { Modal, Spinner, TextInput } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import commands from './searches';

const searchCommands = commands;

type FormData = {
  text: string;
};

function UniversalSearchBar() {
  const [searching, setSearching] = useState(false);
  const [modalNode, setModalNode] = useState<ReactNode | null>(null);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      text: '',
    },
  });

  const onSubmit: SubmitHandler<FormData> = async ({ text }) => {
    try {
      setSearching(true);
      setError('text', {});

      const match = searchCommands.filter((command) => command.is(text))[0];
      if (match) {
        const { url, node, error } = await match.apply(text);
        if (url) {
          window.location.href = url;
          return;
        }

        if (error) {
          setError('text', {
            type: 'manual',
            message: error,
          });
          return;
        }

        if (node) {
          setModalNode(node);
          return;
        }
      }

      setError('text', {
        type: 'manual',
        message: 'Unknown search command',
      });
    } finally {
      setSearching(false);
    }
  };

  // Adjusted handleKeyDown function
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValid) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Modal
        show={modalNode != null}
        onClose={() => setModalNode(null)}
        position="center"
        size="fit"
      >
        <Modal.Header>Search Result</Modal.Header>
        <Modal.Body>
          <div className="space-y-6 ">{modalNode}</div>
        </Modal.Body>
      </Modal>
      <TextInput
        onKeyDown={handleKeyDown}
        disabled={searching}
        placeholder="Search everything, so sicklé"
        className="min-w-64 lg:min-w-96"
        {...register('text', {
          required: true,
        })}
      />
      {errors.text && <div className="text-failure">{errors.text.message}</div>}
      {searching && <Spinner />}
    </>
  );
}

export default UniversalSearchBar;
