import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { RollbarProvider } from '@cg/module-frontend/src/errors';
import { ThemeProvider } from '@cg/module-frontend/src/theme';
import { config } from '@cg/module-frontend';
import router from './routes';

import '@cg/module-frontend/src/theme/app.css';
import './app.css';

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function Application() {
  if (config.boxConfig.isLocal) {
    return (
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    );
  }

  return (
    <RollbarProvider token="4550e424f4224d44b8dcafa5013c67ed">
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </RollbarProvider>
  );
}
