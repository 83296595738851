import React, { useEffect, useRef, useState } from 'react';
import { Label, TextInput, Checkbox } from 'flowbite-react';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { addressString } from '../../utils/general';
import { AddressAutoComplete } from '../map';
import { HelperTip } from '../tooltip';

export type EditableAddress = AddressOrCoordinate & {
  isSecret: boolean;
};

type InputFieldProps = {
  edit: boolean;
  save: () => void;
  value: EditableAddress | null;
  setValue: (value: EditableAddress) => void;
  hideName?: boolean;
};

export default function AddressField({
  edit,
  save,
  value,
  setValue,
  hideName,
}: InputFieldProps) {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const leaveTimeout = useRef<NodeJS.Timeout | null>(null);

  const [addressValue, setAddressValue] = useState(addressString(value));
  const [nameValue, setNameValue] = useState(value?.name);

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const addressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAddressValue(addressString(value));
  }, [value]);

  useEffect(() => {
    const current = addressRef?.current;
    if (!current || !edit) {
      return;
    }

    current.focus();
  }, [edit]);

  // Potentially save the blur event
  // However, when selecting a new field and/or clicking the auto complete, ignore the blur event
  const onBlur = () => {
    if (!mouseIsOver) {
      leaveTimeout.current = setTimeout(save, 100);
    }
  };

  // Clear the save event
  const clearTimer = () => {
    setMouseIsOver(true);
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
    }
  };

  // When the address is selected, update the value
  const onAddressSelect = () => {
    clearTimer();

    const place = autocompleteRef?.current?.getPlace();
    if (!place) {
      return;
    }

    const geometry = place.geometry?.location;
    const latitude = geometry?.lat();
    const longitude = geometry?.lng();
    if (!latitude || !longitude) {
      return;
    }

    const get = (type: string) => {
      return (
        place.address_components?.filter((c) => c.types.includes(type))?.[0]
          ?.long_name ?? ''
      );
    };

    const newValue = {
      ...value,
      name: place.name,
      neighbourhood: get('neighborhood'),
      street1: `${get('street_number')} ${get('route')}`,
      street2: get('subpremise'),
      city: get('locality'),
      state: get('administrative_area_level_1'),
      code: get('postal_code'),
      country: get('country'),
      latitude,
      longitude,
    };
    // @ts-ignore
    setValue({ ...newValue });
    setAddressValue(addressString(newValue));
    setNameValue(newValue.name);
  };

  return (
    <div
      className="flex flex-col space-y-2 text-black"
      onBlur={onBlur}
      onMouseEnter={clearTimer}
      onMouseLeave={() => setMouseIsOver(false)}
      role="presentation"
      tabIndex={-1}
    >
      <div className="flex items-center text-black">
        <Label htmlFor="address" className="w-20 mr-4 ">
          Address:
        </Label>
        <AddressAutoComplete
          autocompleteRef={autocompleteRef}
          onChange={onAddressSelect}
        >
          <TextInput
            ref={addressRef}
            id="address"
            type="text"
            color="primary"
            placeholder="Enter the address of the location"
            className="w-full"
            sizing="md"
            value={addressValue}
            onChange={(e) => {
              setAddressValue(e.target.value);
            }}
          />
        </AddressAutoComplete>
      </div>
      {!hideName && (
        <>
          <div className="flex items-center">
            <Label htmlFor="name" className="w-20 mr-4">
              Name:
            </Label>
            <TextInput
              id="name"
              color="primary"
              type="text"
              placeholder="The name of the location"
              className="w-full"
              sizing="md"
              value={nameValue}
              onChange={(e) => {
                setNameValue(e.target.value);
                // @ts-ignore
                setValue({
                  ...value,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex items-center">
            <Label htmlFor="secret" className="w-20 mr-4 flex">
              Secret:
              <HelperTip content="Keep address secret until 24hrs before, when it'll only become visible to people with tickets." />
            </Label>
            <Checkbox
              id="secret"
              checked={value?.isSecret || false}
              onChange={() => {
                // @ts-ignore
                setValue({
                  ...value,
                  isSecret: !value?.isSecret,
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
