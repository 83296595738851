import {
  Article,
  Button,
  SummaryOfSales,
  Alert,
  Card,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useEffect } from 'react';
import { toast } from '@cg/module-frontend';
import { UserWithTicket } from '~/generated/models/UserWithTicket.ts';
import { TicketTier } from '~/generated/models/TicketTier.ts';
import { Ticket } from '~/generated/models/Ticket';
import {
  generateReportHook,
  payExperienceHook,
} from '~/generated/clients/background/experiences/BackgroundExperiences.hooks.ts';
import { Experience } from '~/generated/models/Experience';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus.ts';

export type SalesProps = {
  experience: Experience;
  users: UserWithTicket[];
  tiers: TicketTier[];
  setPaid: () => void;
  hasPaymentAddress: boolean;
};
export default function Sales({
  users,
  tiers,
  experience,
  setPaid,
  hasPaymentAddress,
}: SalesProps) {
  const [downloaded, setDownloaded] = React.useState(false);
  const tickets = users.map((u) => u?.ticket).filter(Boolean) as Ticket[];
  const {
    call: generateReport,
    data,
    calling: generating,
  } = generateReportHook(false);
  const {
    call: emailReport,
    calling: emailing,
    error: payError,
  } = payExperienceHook(false);

  useEffect(() => {
    if (!data?.data || downloaded) {
      return;
    }

    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${data?.data}`;
    link.download = `${experience.uniqueName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloaded(true);
  }, [data]);

  const email = async () => {
    const response = await emailReport({
      ids: {
        experienceId: experience.id,
      },
    });
    if (response.succeeded) {
      setPaid();
      toast.success('Report emailed successfully');
    }
  };

  const report = async () => {
    setDownloaded(false);
    await generateReport({
      ids: {
        experienceId: experience.id,
      },
    });
  };

  return (
    <Article>
      <Card>
        <div className="flex items-center justify-between w-full">
          <h2 className="text-left">Summary of Sales</h2>
        </div>
        {payError && <Alert color="failure">{payError.message}</Alert>}
        <SummaryOfSales tickets={tickets} tiers={tiers} />
        {experience.status === ExperienceStatus.Published &&
          hasPaymentAddress && (
            <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
              <Button
                color="secondary"
                onClick={report}
                className="flex-1"
                loading={generating}
                disabled={generating || emailing}
              >
                Generate & Download Report
              </Button>
              <Button
                color="primary"
                onClick={email}
                className="flex-1"
                loading={emailing}
                disabled={generating || emailing}
              >
                Email Report & Mark Complete
              </Button>
            </div>
          )}
      </Card>
    </Article>
  );
}
