import { Article, Card } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { ExperiencesListTable } from '~/components';
import { ExperienceWithTicketsAndHost } from '~/generated/models/ExperienceWithTicketsAndHost.ts';

type HostExperiencesProps = {
  experiences: ExperienceWithTicketsAndHost[];
};

export default function HostExperiences({ experiences }: HostExperiencesProps) {
  return (
    <Article>
      <Card>
        <h2>Experiences</h2>
        <ExperiencesListTable experiences={experiences} />
      </Card>
    </Article>
  );
}
