import {
  Button,
  Label,
  Modal,
  Textarea,
  TextInput,
} from '@cg/module-frontend/src/components';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { FeatureFlag } from '~/generated/models/FeatureFlag';
import { createFeatureFlagHook } from '~/generated/clients/background/features/BackgroundFeatures.hooks';

type NewFeatureFlagProps = {
  close: () => void;
  refetch: () => void;
};

function NewFeatureFlag({ refetch, close }: NewFeatureFlagProps) {
  const {
    error,
    call: createFeatureFlag,
    data: newFlag,
  } = createFeatureFlagHook(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FeatureFlag>({
    mode: 'onBlur',
    defaultValues: {
      feature: '',
      description: '',
    },
  });

  const onSubmit: SubmitHandler<FeatureFlag> = async (data: FeatureFlag) => {
    data.enabled = false;
    await createFeatureFlag({
      body: {
        ...data,
      },
    });
  };

  useEffect(() => {
    if (newFlag) {
      close();
      refetch();
    }
  }, [newFlag]);

  useEffect(() => {
    if (error) {
      setError('feature', {
        type: 'manual',
        message: error.message,
      });
    }
  }, [error]);

  return (
    <Modal show onClose={close}>
      <Modal.Header>Add Feature Flag</Modal.Header>
      <Modal.Body>
        <div>
          <p className="text-base leading-relaxed">
            Use this form to create a new Feature Flag. Once you have created
            it, you can either assign it to a User or enable it for everyone.
          </p>
          <p className="text-base leading-relaxed mt-2 mb-4">
            <i>Note: </i> It takes about 5min for cache to clear. So after
            enabling/disabling, you will have to wait before seeing the changes
          </p>
          <div className="flex flex-col">
            <Label htmlFor="feature" className="px-2 py-1" required>
              Feature Name
            </Label>
            <TextInput
              id="feature"
              placeholder="PLAYGROUND_ACCESS"
              {...register('feature', {
                validate: (value: string) => {
                  if (!value) {
                    return 'Please enter a feature name';
                  }

                  if (value.length < 5) {
                    return 'Feature name must be at least 5 characters';
                  }

                  if (!/^[a-zA-Z0-9_-]{5,}$/.test(value)) {
                    return 'Feature name must be alphanumeric and can contain _ or -';
                  }

                  return true;
                },
              })}
              helperText={
                errors.feature?.message ? (
                  <span className="text-failure ml-3">
                    {errors.feature?.message}
                  </span>
                ) : null
              }
            />
          </div>
          <div className="flex flex-col mt-4">
            <Label htmlFor="description" className="px-2 py-1" required>
              Feature Description
            </Label>
            <Textarea
              id="description"
              placeholder="Access to using Playground"
              {...register('description', {
                validate: (value: string) => {
                  if (!value) {
                    return 'Please enter a description';
                  }

                  if (value.length < 20) {
                    return 'Description must be at least 20 characters';
                  }

                  return true;
                },
              })}
              helperText={
                errors.description?.message ? (
                  <span className="text-failure ml-3">
                    {errors.description?.message}
                  </span>
                ) : null
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          className="ml-auto"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewFeatureFlag;
