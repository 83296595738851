import * as React from 'react';

type Props = {
  title: string;
  value: number;
  onClick: () => void;
};

export default function DashboardCard({ title, value, onClick }: Props) {
  return (
    <button
      className="flex gap-5 justify-between px-4 py-1 bg-white rounded border border-solid cursor-pointer"
      type="button"
      onClick={onClick}
    >
      <div className="flex flex-col py-1 items-start">
        <h3>{title}</h3>
        <span>{value}</span>
      </div>
    </button>
  );
}
