import React from 'react';

import generateSummaryOfSales from '@cg/common/src/experiences/summaryOfSales';
import { TicketTier } from '~/generated/models/TicketTier';
import { Ticket } from '~/generated/models/Ticket.ts';
import { Table, TableHeaderItem, TableRowItem } from '../table';
import { HelperTip } from '../tooltip';

type SalesProp = {
  tiers: TicketTier[];
  tickets: Ticket[];
};

export default function SummaryOfSales({ tickets, tiers }: SalesProp) {
  const { grossRevenueDollar, netRevenueDollar, stripeFeeDollar, list } =
    generateSummaryOfSales(tickets, tiers);

  const headers: TableHeaderItem[] = [
    'Ticket Tier',
    'Quantity',
    'Price',
    'Sales',
  ];
  const rows: TableRowItem[] = list.map((tier) => {
    return {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: `${tier.id.getValue()}`,
      cells: [tier.name, tier.count, tier.priceDollar, tier.totalSalesDollar],
    };
  });
  rows.push(
    {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: 'gross-revenue',
      cells: [
        {
          label: <span className="font-semibold">Gross Sales</span>,
          span: 3,
        },
        {
          label: <span className="font-semibold">{grossRevenueDollar}</span>,
          className: 'border-l-0',
        },
      ],
    },
    {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: 'processing-fee',
      cells: [
        {
          label: (
            <div className="flex items-center">
              <span>Payment Processing Fee</span>
              <HelperTip content="We get charged a small fee (2.9% + $0.30) every time we process a credit card payment, which is reflected here." />
            </div>
          ),
          span: 3,
        },
        {
          label: stripeFeeDollar,
          className: 'border-l-0',
        },
      ],
    },
    {
      className: 'bg-white border-grey-darker cursor-pointer',
      key: 'net-revenue',
      cells: [
        {
          label: <span className="font-extrabold">Net Sales</span>,
          span: 3,
        },
        {
          label: <span className="font-extrabold">{netRevenueDollar}</span>,
          className: 'border-l-0',
        },
      ],
    },
  );

  return <Table headers={headers} rows={rows} />;
}
