import {
  Article,
  Button,
  TableRowItem,
  Table,
  UserAvatar,
  Spinner,
} from '@cg/module-frontend/src/components';
import { GhostIcon, TrashIcon } from '@cg/module-frontend/src/icons';
import * as React from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { UserId } from '@cg/common/src/ids';
import AddHostUser from '~/pages/hosts/host/components/AddHostUser';
import { Host } from '~/generated/models/Host';
import { HostUser } from '~/generated/models/HostUser';

type HostUsersProps = {
  host: Host;
  users: HostUser[];
  modifying: boolean;
  changeOwner: (userId: UserId) => void;
  removeUser: (userId: UserId) => void;
  addUser: (userId: UserId) => void;
  updateUser: (userId: UserId, isGhost: boolean) => void;
};
export default function HostUsers({
  host,
  users,
  changeOwner,
  removeUser,
  modifying,
  addUser,
  updateUser,
}: HostUsersProps) {
  const navigate = useNavigate();
  const [addOrganizer, showAddOrganizer] = React.useState(false);
  const [confirmRemove, setConfirmRemove] = React.useState(-1);

  const headers = ['Name', 'Email', 'Owner', 'Ghost', 'Remove'];
  const rows: TableRowItem[] = users.map((user, index) => {
    const confirmingRemove = confirmRemove === index;
    const isOwner = host.ownerId.isEqual(user.id);

    return {
      key: user.id.getValue(),
      className: 'bg-white border-grey-darker cursor-pointer',
      onClick: () => navigate(`/users/${user.id.getValue()}`),
      cells: [
        <div className="flex flex-row items-center">
          <UserAvatar user={user} iconSize="md" expandable />
          <span className="ml-2 whitespace-nowrap">
            {user.firstName} {user.lastName}
          </span>
        </div>,
        user.email,
        {
          label: (
            <>
              {isOwner && (
                <span className="p-2 rounded bg-primary text-white">Owner</span>
              )}
              {!isOwner && (
                <Button
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeOwner(user.id);
                  }}
                >
                  Make Owner
                </Button>
              )}
            </>
          ),
        },
        {
          label: (
            <div className="flex flex-row space-x-2 items-center justify-center">
              {user.isGhost && <GhostIcon className="size-6" />}
              {user.isGhost && (
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateUser(user.id, false);
                  }}
                >
                  Remove Ghost
                </Button>
              )}
              {!user.isGhost && (
                <Button
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateUser(user.id, true);
                  }}
                >
                  Make Ghost
                </Button>
              )}
            </div>
          ),
        },
        {
          label: (
            <div className="flex items-center space-x-2">
              {confirmingRemove && <p>Are you sure?</p>}
              <TrashIcon
                className={classNames(
                  'size-6 cursor-pointer opacity-65 hover:opacity-90',
                  {
                    'text-failure !opacity-100': confirmingRemove,
                  },
                )}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setConfirmRemove(index);
                  if (confirmingRemove) {
                    removeUser(user.id);
                  }
                }}
              />
            </div>
          ),
        },
      ],
    };
  });

  return (
    <>
      {addOrganizer && (
        <AddHostUser
          onClose={() => {
            showAddOrganizer(false);
          }}
          onSave={addUser}
        />
      )}
      <Article>
        <div className="flex justify-between items-center">
          <h2 className="justify-center text-black">Organizers</h2>
          <Button color="primary" onClick={() => showAddOrganizer(true)}>
            Add Organizer
          </Button>
        </div>

        <div className="flex flex-col relative">
          {modifying && <Spinner className="absolute z-20 top-1/2 left-1/2" />}

          <Table
            headers={headers}
            rows={rows}
            className={classNames('relative z-0', {
              'opacity-50': modifying,
            })}
          />
        </div>
      </Article>
    </>
  );
}
