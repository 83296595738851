import { Loader } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { getHostIdParam } from '@cg/module-frontend/src/hooks/params.hooks.ts';
import { useEffect } from 'react';
import { fetchHostHook } from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import HostPage from './HostPage';
import { fetchUserHook } from '~/generated/clients/background/users/BackgroundUsers.hooks';

export default function Host() {
  const hostId = getHostIdParam();
  const {
    data: host,
    calling: fetchingHost,
    call,
  } = fetchHostHook(true, {
    ids: {
      hostId,
    },
  });
  const {
    data: owner,
    calling: fetchingOwner,
    call: fetchOwner,
  } = fetchUserHook(false);

  useEffect(() => {
    if (!host) {
      return;
    }

    fetchOwner({
      ids: {
        userId: host.ownerId,
      },
    });
  }, [host]);

  const refetchHost = () => call({ ids: { hostId } });

  return (
    <Loader horizontal loading={fetchingHost || fetchingOwner}>
      {host && owner && (
        <HostPage refetchHost={refetchHost} host={host} owner={owner} />
      )}
    </Loader>
  );
}
